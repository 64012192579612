import React, { memo, useEffect } from "react";
import { ISignalRConnectionInfo } from "src/core/common/model/SignalRConnectionInfo";
import { addGroup, getWebSocketConnectionInfo, signalRConnectionInit } from "src/core/Services/SignalR/SignalR-Service";
import * as signalR from '@microsoft/signalr';
import * as SignalRStore from "src/store/SignalR/SignalRStore";
import { useDispatch } from "react-redux";
import { FormGroupStatus } from "src/core/domain/models/Organizer/Organizer";
import { DisplayDownloadFile } from "src/core/utilities/DisplayDownloadFile";
import { container } from "src/startup/inversify.config";
import { ILoader } from "src/core/utilities/ui/Loader";
import { TYPES } from "src/startup/types";

interface SignalRProps {
    client_id: string;
}

interface ConnectionDetails {
    url: string,
    token: string
}

export interface INotificationMessage {
    organizerId: number;
    companyId: number;
    notificationType: NotificationType;
    data: any;
    clientGuids: string[],
    accountId: number;
    organizerStatus: FormGroupStatus;
}

export interface IZipCompleteNotificationMessage {
    notificationType: NotificationType;
    autoDownload: boolean;
    isZipSuccess: boolean;
    uri: string;
    fileName: string;
    filePath: string;
    clientGuid : string;
}
const loader = container.get<ILoader>(TYPES.ILoader);
export enum NotificationType {
}

export const SignalRWebSocket: React.FC<SignalRProps> = ({ client_id }) => {
    var user = "";
    var group = "";
    var apiBaseUrl = "";
    var token = "";

    const dispatch = useDispatch();

    useEffect(() => {
        getWebSocketConnectionInfo(client_id, handleGetWebSocketConnectionInfoResponse);
    }, [client_id]);

    const handleGetWebSocketConnectionInfoResponse = (connectionDetails: ConnectionDetails) => {
        apiBaseUrl = connectionDetails.url;
        token = connectionDetails.token;
        user = client_id;
        group = client_id;

        signalRConnectionInit(user, apiBaseUrl, connectionDetails.token, initiateConnection);
    };

    const initiateConnection = (info: ISignalRConnectionInfo) => {
        info.accessToken = info.accessToken || info.accessKey;
        info.url = info.url || info.endpoint;
        const options = {
            accessTokenFactory: () => info.accessToken
        };
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(info.url, options)
            .configureLogging(signalR.LogLevel.Information)
            .build();
        connection.onclose(() => {
            startConnection(connection);
        });
        startConnection(connection);
    };

    const startConnection = (connection: any) => {
        connection.start()
            .then(function () {
                connection.on('DocumentStatusUpdated', documentStatusUpdated);
                connection.on('ZipCompletion', zipCompletion);
                addGroup(user, group, apiBaseUrl, token);
                connection.invoke('getConnectionId')
                    .then(function (connectionId: any) {
                        // Send the connectionId to controller
                    });
            })
            .catch(function (err: any) {
                console.error(err);
                setTimeout(startConnection, 5000, connection);
            });
    };

    const documentStatusUpdated = (notificationMessage: INotificationMessage) => {
        var clientGuids = notificationMessage.clientGuids;
        if (clientGuids.includes(client_id)) {
            var statusString: string = notificationMessage.organizerStatus as unknown as string;
            const newStatus = FormGroupStatus[statusString as keyof typeof FormGroupStatus];
            dispatch(SignalRStore.actionCreators.updateSignalROrganizerStatus(newStatus));
        }
    };

    const zipCompletion = (ZipCompleteNotificationMessage: IZipCompleteNotificationMessage) => {
        const { autoDownload, fileName, isZipSuccess, uri , clientGuid} = ZipCompleteNotificationMessage;

        if (autoDownload && isZipSuccess && clientGuid === client_id) {
            onDownloadFile(uri, fileName, ".zip");
        }
    };


    const onDownloadFile = async (url: string, fileName: string = "file", extention :string, isReadMode?: boolean) => {

        try {
            // Fetch the file from the provided URL
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`Failed to fetch file: ${response.statusText}`);
            }
            // Convert the response to a blob
            const blob = await response.blob();
           let displayDownloadFile = new DisplayDownloadFile();
            const fileExtension = extention.startsWith('.') ? extention : `.${extention}`;
            const finalFileName = fileName.endsWith(fileExtension) ? fileName : `${fileName}${fileExtension}`;
            displayDownloadFile.showFile(blob, finalFileName);
            loader.hide();
        } catch (error) {
            
            loader.hide();
        }
    };
    return <div />;
}

export const MemoizedSignalRWebSocket = memo(SignalRWebSocket);