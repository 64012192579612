import * as React from 'react';

export interface SvgIconProps {
    width?: number;
    height?: number;
    className?: string;
    onClick?: any;
    primaryColor?: string;
    secondaryColor?: string;
    fillColor?: string;
}


//Header Icon components
//--------------------------------------------
export const SvgIconAccountHeader: React.SFC<SvgIconProps> = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 24} height={props.height || 24} className={props.className} onClick={props.onClick} viewBox="0 0 24 24" role="img">
        <path fill={props.fillColor || "#FFFFFF"} d="M14.799,23.953H9.2c-0.692,0.002-1.255-0.555-1.26-1.246v-2.9c-0.252-0.13-0.498-0.271-0.736-0.425 l-2.525,1.45c-0.6,0.343-1.364,0.141-1.712-0.456l-2.8-4.821c-0.165-0.287-0.21-0.627-0.124-0.945 c0.086-0.32,0.296-0.592,0.584-0.756l2.53-1.451c-0.006-0.143-0.01-0.284-0.01-0.424s0.004-0.282,0.01-0.423l-2.53-1.45 C0.339,9.94,0.129,9.668,0.043,9.348c-0.086-0.319-0.041-0.66,0.125-0.946l2.799-4.818c0.347-0.596,1.111-0.8,1.71-0.457 l2.527,1.45C7.442,4.424,7.688,4.282,7.94,4.152v-2.9c0-0.333,0.133-0.652,0.37-0.888C8.546,0.13,8.866,0,9.2,0h5.599 c0.692-0.002,1.256,0.555,1.26,1.246v2.9c0.252,0.13,0.498,0.272,0.736,0.426l2.525-1.45c0.6-0.344,1.363-0.14,1.712,0.455 l2.8,4.819c0.165,0.286,0.21,0.627,0.124,0.946c-0.086,0.32-0.296,0.591-0.583,0.756l-2.532,1.45 c0.007,0.142,0.011,0.283,0.011,0.423c0,0.14-0.004,0.282-0.011,0.424l2.532,1.45c0.287,0.165,0.497,0.438,0.583,0.757 s0.041,0.659-0.124,0.945l-2.8,4.819c-0.347,0.595-1.111,0.799-1.71,0.456l-2.527-1.45c-0.238,0.153-0.484,0.296-0.736,0.425v2.9 c0,0.334-0.132,0.654-0.369,0.89C15.454,23.822,15.133,23.954,14.799,23.953z M9.686,22.218h4.626v-3.534l0.528-0.226 c0.493-0.212,0.96-0.481,1.39-0.802l0.461-0.34l3.076,1.767l2.306-3.972l-3.077-1.767l0.064-0.564c0.062-0.534,0.062-1.073,0-1.607 l-0.064-0.564l3.077-1.767L19.768,4.87l-3.074,1.767l-0.461-0.34c-0.431-0.32-0.897-0.59-1.391-0.802L14.313,5.27V1.736H9.686V5.27 L9.157,5.495c-0.493,0.212-0.96,0.481-1.39,0.802l-0.46,0.34L4.232,4.87L1.925,8.842l3.077,1.766l-0.064,0.565 c-0.061,0.534-0.061,1.073,0,1.607l0.064,0.564l-3.076,1.767l2.306,3.972l3.074-1.767l0.46,0.341c0.43,0.319,0.897,0.59,1.39,0.801 l0.53,0.226V22.218z M12,16.1c-2.286,0-4.146-1.851-4.146-4.123c0-2.274,1.86-4.123,4.146-4.123s4.146,1.85,4.146,4.123 S14.286,16.1,12,16.1z M12,9.591c-1.32,0.005-2.384,1.076-2.38,2.392c0.003,1.317,1.074,2.381,2.393,2.38 c1.319-0.002,2.387-1.07,2.387-2.386C14.396,10.657,13.322,9.59,12,9.591z" />
        <path fill={props.fillColor || "#FFFFFF"} d="M11.999,16.1c-2.286,0-4.146-1.85-4.146-4.123c0-2.274,1.86-4.123,4.146-4.123s4.146,1.85,4.146,4.123 S14.285,16.1,11.999,16.1L11.999,16.1z M11.999,9.59c-1.32,0.006-2.384,1.077-2.38,2.393c0.003,1.317,1.074,2.381,2.393,2.38 c1.319-0.002,2.386-1.07,2.386-2.386C14.395,10.657,13.32,9.59,11.999,9.59L11.999,9.59z" />
    </svg>;
}


export const SvgIconCalendarHeader: React.SFC<SvgIconProps> = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 24} height={props.height || 24} className={props.className} onClick={props.onClick} viewBox="0 0 24 24" role="img">
        <path fill={props.fillColor || "#FFFFFF"} d="M16,1.6v2.933c0,0.884,0.716,1.6,1.6,1.6c0.885,0,1.601-0.716,1.601-1.6V1.6C19.2,0.716,18.484,0,17.6,0 C16.716,0,16,0.716,16,1.6z" />
        <path fill={props.fillColor || "#FFFFFF"} d="M21.6,2.933h-1.332v1.6c0,1.473-1.194,2.667-2.668,2.667c-1.473,0-2.666-1.194-2.666-2.667v-1.6H9.067v1.6 C9.067,6.006,7.873,7.2,6.4,7.2S3.733,6.006,3.733,4.533v-1.6H2.4C1.075,2.935,0.001,4.008,0,5.333V21.6 c0.001,1.325,1.075,2.399,2.4,2.4h19.2c1.325-0.001,2.399-1.075,2.4-2.4V5.333C23.999,4.008,22.925,2.935,21.6,2.933z M22.934,21.6 c0,0.736-0.598,1.332-1.334,1.334H2.4c-0.736,0-1.332-0.598-1.333-1.334V8.8h21.867V21.6z" />
        <path fill={props.fillColor || "#FFFFFF"} d="M4.8,1.6v2.933c0,0.884,0.716,1.6,1.6,1.6c0.884,0,1.6-0.716,1.6-1.6V1.6C8,0.716,7.284,0,6.4,0 C5.516,0,4.8,0.716,4.8,1.6z M10.075,11.604h4v3h-4V11.604z M16.684,11.604h4v3h-4V11.604z M3.467,11.604h4v3h-4V11.604z M10.075,17.654h4v3h-4V17.654z M16.684,17.654h4v3h-4V17.654z M3.467,17.654h4v3h-4V17.654z" />
    </svg>;
}


export const SvgIconContactHeader: React.SFC<SvgIconProps> = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 24} height={props.height || 24} className={props.className} onClick={props.onClick} viewBox="0 0 24 24" role="img">
        <path fill={props.fillColor || "#FFFFFF"} d="M12.053,1.448c0,0,4.402,0.202,4.402,4.552S10.643,10.35,12,10.35c1.357,0-4.35,0-4.35-4.35 S12.053,1.448,12.053,1.448z M12.15,15.15c4.966,0,10.2,3.285,10.2,4.725v2.475H1.95v-2.475C1.95,18.436,7.184,15.15,12.15,15.15z M6,6c0,3.315,2.685,6,6,6c3.314,0,6-2.685,6-6s-2.686-6-6-6C8.685,0,6,2.685,6,6z M12,13.5c-4.005,0-12,2.01-12,6V24h24v-4.5 C24,15.51,16.005,13.5,12,13.5z" />
    </svg>;
}

//Wizard Icons
//--------------------------------------------
export const SvgIconSummaryWizardActive: React.SFC<SvgIconProps> = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 68} height={props.height || 68} className={props.className} onClick={props.onClick} viewBox="0 0 68 68" role="img">
        <path fill={props.secondaryColor || "#0973BA"} d="M34,68C15.252,68,0,52.748,0,34S15.252,0,34,0s34,15.252,34,34S52.748,68,34,68L34,68z" />
        <path fill={props.fillColor || "#FEFEFE"} d="M34,2C16.355,2,2,16.355,2,34c0,17.645,14.355,32,32,32c17.645,0,32-14.355,32-32C66,16.355,51.645,2,34,2 L34,2z" />
        <path fill={props.primaryColor || "#8BC656"} d="M39.387,16.98c-1.046-1.052-2.91-1.064-2.99-1.064H22.604c-1.988,0-2.604,0.66-2.604,2.699v30.6 c0,2.041,0.616,2.701,2.604,2.701h22.792c1.986,0,2.604-0.66,2.604-2.701V25.329L39.387,16.98z" />
        <path fill={props.fillColor || "#FEFEFE"} d="M38.047,18.383l6.742,6.533h-4.742c-1.104,0-2-0.897-2-2V18.383z" />
        <path fill={props.fillColor || "#FEFEFE"} d="M44.396,49.955H23.604c-0.934,0-1.689-0.779-1.689-1.74v-28.6c0-0.959,0.756-1.739,1.689-1.739h12.442 v5.04c0,2.206,1.794,4,4,4h6.038v21.299C46.085,49.176,45.329,49.955,44.396,49.955z" />
        <path fill={props.primaryColor || "#8BC656"} d="M28.047,27.916h7v2h-7V27.916z M28.047,33.916h12v2h-12V33.916z M28.047,40.916h12v2h-12V40.916z" />
    </svg>;
}


export const SvgIconSignWizardActive: React.SFC<SvgIconProps> = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 68} height={props.height || 68} className={props.className} onClick={props.onClick} viewBox="0 0 68 68" role="img">
        <path fill={props.secondaryColor || "#0973BA"} d="M34,68C15.252,68,0,52.748,0,34S15.252,0,34,0s34,15.252,34,34S52.748,68,34,68L34,68z" />
        <path fill={props.fillColor || "#FEFEFE"} d="M34,2C16.355,2,2,16.355,2,34c0,17.645,14.355,32,32,32c17.645,0,32-14.355,32-32C66,16.355,51.645,2,34,2 L34,2z" />
        <path fill={props.secondaryColor || "#0973BA"} d="M19.114,52c-0.814,0-1.508-0.219-2.064-0.659c-1.236-0.981-1.511-2.892-0.751-5.239 c0.102-0.313,0.121-0.494,0.122-0.588c-0.837-0.142-4.125,1.571-7.215,3.793l-0.85-1.33c2.701-1.942,7.468-5.009,9.039-3.731 c0.58,0.472,0.692,1.271,0.335,2.376c-0.524,1.622-0.441,2.904,0.224,3.433c0.936,0.74,4.026,1.016,8.118-2.083l1.304,1.202 C24.49,51.355,20.962,51.999,19.114,52L19.114,52z" />
        <path fill={props.primaryColor || "#8BC656"} d="M30.001,41.126l5.163,5.025l-7.225,1.989L30.001,41.126z M54.231,17.201l4.904,4.886 c0.678,0.673,0.678,1.767,0,2.443L38.273,45.412l-1.911,0.409l-5.991-5.967l0.563-1.9l8.605-8.49l1.192,1.187l9.822-9.783 l4.902,4.882l1.226-1.223l-6.133-6.104l1.229-1.221C52.455,16.526,53.553,16.526,54.231,17.201z M48.088,16l2.452,2.441 l-9.811,9.767l0.036-4.917L48.088,16z" />
    </svg>;
}


export const SvgIconReviewWizardActive: React.SFC<SvgIconProps> = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 100} height={props.height || 100} className={props.className} onClick={props.onClick} viewBox="0 0 100 100" role="img">
        <path fill={props.secondaryColor || "#0973BA"} d="M50,100C22.43,100,0,77.57,0,50S22.43,0,50,0s50,22.43,50,50S77.57,100,50,100L50,100z" />
        <path fill={props.fillColor || "#FEFEFE"} d="M50,2C23.533,2,2,23.533,2,50c0,26.468,21.533,48,48,48c26.468,0,48-21.532,48-48C98,23.533,76.468,2,50,2 L50,2z" />
        <path fill={props.primaryColor || "#8BC656"} d="M42.792,32.813h10.416v3.151H42.792V32.813z M42.792,41.22h19.791v3.152H42.792V41.22z M42.792,51.728 H54.57L52,54.88h-9.208V51.728z" />
        <path fill={props.secondaryColor || "#0973BA"} d="M82.8,80.767l-9.385-9.4c1.22-1.82,1.874-3.961,1.874-6.201c0-2.982-1.159-5.787-3.264-7.896 c-2.105-2.109-4.903-3.27-7.881-3.27c-2.977,0-5.774,1.162-7.881,3.27C54.159,59.378,53,62.181,53,65.163s1.16,5.785,3.264,7.895 c2.105,2.107,4.904,3.271,7.881,3.271c2.264,0,4.422-0.672,6.252-1.92l9.377,9.393c0.315,0.316,0.897,0.248,1.301-0.154 l1.574-1.578C83.049,81.667,83.118,81.083,82.8,80.767L82.8,80.767z M64.072,73.286c-4.53,0-8.215-3.686-8.215-8.215 s3.685-8.215,8.215-8.215c4.529,0,8.214,3.686,8.214,8.215S68.602,73.286,64.072,73.286z" />
        <path fill={props.primaryColor || "#8BC656"} d="M28.546,27.981c-0.705,0-2.744,2.103-2.744,2.828v41.451c0,0.73,2.039,2.826,2.744,2.826h19.382v2.912 H28.546c-3.065,0-5.546-2.568-5.546-5.738v-41.45c0-3.167,2.481-5.736,5.546-5.736V27.981z" />
        <path fill={props.primaryColor || "#8BC656"} d="M70.604,52.478l2.816,1.611V30.232L60.755,17.608C59.219,16.019,56.477,16,56.359,16H37.554 c-2.92,0-5.299,2.51-5.299,5.592v43.251c0,3.086,2.379,5.596,5.299,5.596h9.229l-1.681-2.967h-7.548 c-1.373,0-2.482-1.176-2.482-2.629V21.592c0-1.449,1.109-2.627,2.482-2.627h18.293v7.621c0,3.336,2.637,6.049,5.879,6.049h8.877 V52.478z M61.727,29.609c-1.619,0-2.939-1.355-2.939-3.023v-6.856l9.912,9.879L61.727,29.609L61.727,29.609z" />
    </svg>;
}


export const SvgIconDistributeK1sWizardActive: React.SFC<SvgIconProps> = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 100} height={props.height || 100} className={props.className} onClick={props.onClick} viewBox="0 0 100 100" role="img">
        <path fill={props.secondaryColor || "#0973BA"} d="M50,100C22.431,100,0,77.57,0,50C0,22.431,22.431,0,50,0c27.57,0,50,22.431,50,50 C100,77.57,77.57,100,50,100L50,100z" />
        <path fill={props.fillColor || "#FEFEFE"} d="M50,2C23.533,2,2,23.533,2,50c0,26.469,21.533,48,48,48c26.468,0,48-21.531,48-48C98,23.533,76.468,2,50,2 L50,2z" />
        <path fill={props.primaryColor || "#8BC656"} d="M43.792,49.221h19.791v3.152H43.792V49.221z M43.792,56.729h19.791v3.152H43.792V56.729z" />
        <path fill={props.secondaryColor || "#0973BA"} d="M54,82.457c0-8.686,6.835-15.771,15.42-16.18c0.246-0.01,0.439-0.213,0.439-0.459v-5.355 c0-0.182,0.1-0.34,0.265-0.416c0.165-0.078,0.351-0.055,0.489,0.061l12.49,10.322c0.108,0.09,0.168,0.211,0.168,0.355 c0,0.143-0.061,0.264-0.168,0.355L70.613,81.46c-0.139,0.117-0.324,0.14-0.489,0.062s-0.265-0.232-0.265-0.416v-5.004 c0-0.131-0.049-0.244-0.146-0.334c-0.095-0.091-0.21-0.134-0.341-0.127c-6.432,0.321-11.872,4.395-14.186,10.071 c-0.078,0.188-0.266,0.306-0.471,0.285c-0.207-0.021-0.369-0.168-0.41-0.371C54.107,84.602,54,83.541,54,82.457L54,82.457z" />
        <path fill={props.secondaryColor || "#0973BA"} d="M46.341,40.99l-1.143,1.153V45H43V35h2.198v4.533l0.967-1.243L48.883,35h2.703l-3.788,4.444L51.695,45 H49.08L46.341,40.99z M51.695,39.167h5.218v1.667h-5.218V39.167z M63,45h-1.982v-7.641l-2.365,0.733v-1.61L62.787,35H63V45z" />
        <path fill={props.primaryColor || "#8BC656"} d="M29.632,27.981c-0.715,0-2.787,2.103-2.787,2.829v41.451c0,0.729,2.072,2.826,2.787,2.826h19.687V78H29.632 C26.521,78,24,75.432,24,72.262v-41.45c0-3.167,2.521-5.737,5.632-5.737V27.981z" />
        <path fill={props.primaryColor || "#8BC656"} d="M72.35,56.479l2.859,2.611V30.232L62.348,17.609C60.787,16.02,58.002,16,57.881,16H38.78 c-2.965,0-5.381,2.51-5.381,5.592v43.25c0,3.088,2.418,5.598,5.381,5.598h13.438l3.37-2.967H38.78c-1.395,0-2.521-1.178-2.521-2.631 v-43.25c0-1.448,1.128-2.627,2.521-2.627h18.582v7.621c0,3.336,2.678,6.05,5.971,6.05h9.018V56.479z M63.332,29.61 c-1.645,0-2.984-1.356-2.984-3.024V19.73l10.066,9.879L63.332,29.61L63.332,29.61z" />
    </svg>;
}


export const SvgIconMakePaymentsWizardActive: React.SFC<SvgIconProps> = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 100} height={props.height || 100} className={props.className} onClick={props.onClick} viewBox="0 0 100 100" role="img">
        <path fill={props.secondaryColor || "#0973BA"} d="M50,100C22.43,100,0,77.57,0,50S22.43,0,50,0s50,22.43,50,50S77.57,100,50,100L50,100z" />
        <path fill={props.fillColor || "#FEFEFE"} d="M50,2C23.533,2,2,23.533,2,50c0,26.468,21.533,48,48,48c26.468,0,48-21.532,48-48C98,23.533,76.468,2,50,2 L50,2z" />
        <path fill={props.primaryColor || "#8BC656"} d="M50,86.51c-20.115,0-36.48-16.044-36.48-35.765C13.52,31.024,29.885,14.98,50,14.98 s36.48,16.044,36.48,35.765C86.48,70.466,70.115,86.51,50,86.51L50,86.51z M50,17.98c-18.461,0-33.48,14.698-33.48,32.765 C16.52,68.812,31.539,83.51,50,83.51s33.48-14.698,33.48-32.765C83.48,32.678,68.461,17.98,50,17.98L50,17.98z" />
        <path fill={props.secondaryColor || "#0973BA"} d="M60.234,59.205c-0.51,0.963-1.201,1.753-2.078,2.368c-0.877,0.618-1.889,1.079-3.039,1.389 c-0.93,0.249-1.913,0.39-2.926,0.47v5.197h-3.248v-5.184c-1.773-0.133-3.52-0.482-5.2-1.038c-1.75-0.58-3.333-1.339-4.744-2.276 l2.393-4.404c0.207,0.197,0.569,0.457,1.078,0.776c0.51,0.321,1.13,0.642,1.862,0.963s1.536,0.61,2.412,0.87 c0.71,0.21,1.447,0.354,2.2,0.457v-6.42l-0.925-0.236c-1.334-0.345-2.49-0.722-3.472-1.129c-0.98-0.406-1.797-0.881-2.45-1.425 c-0.64-0.526-1.144-1.184-1.47-1.924c-0.328-0.74-0.49-1.615-0.49-2.627c0-1.259,0.228-2.375,0.686-3.35 c0.457-0.974,1.084-1.8,1.882-2.48c0.797-0.678,1.745-1.214,2.843-1.61c1.047-0.375,2.182-0.607,3.395-0.715v-4.248h3.248v4.263 c1.422,0.16,2.762,0.466,4.004,0.941c1.451,0.556,2.726,1.18,3.824,1.87l-2.392,4.144c-0.157-0.173-0.452-0.382-0.883-0.63 c-0.496-0.277-1.007-0.53-1.53-0.758c-0.63-0.274-1.277-0.508-1.939-0.703c-0.357-0.105-0.719-0.193-1.084-0.264v6.5 c1.304,0.335,2.5,0.692,3.572,1.073c1.111,0.395,2.053,0.888,2.824,1.48c0.756,0.575,1.366,1.303,1.784,2.129S61,54.518,61,55.727 C61,57.083,60.744,58.244,60.234,59.205z M45.16,43.581c0,0.891,0.34,1.568,1.023,2.026c0.58,0.391,1.422,0.746,2.497,1.07v-6.146 C46.342,40.798,45.16,41.808,45.16,43.581z M53.889,54.578c-0.44-0.311-1.009-0.6-1.688-0.876v5.268 c1.755-0.256,2.64-1.081,2.64-2.487C54.84,55.659,54.523,55.025,53.889,54.578z" />
    </svg>;
}

//Summary Page Buttons
//--------------------------------------------



export const SvgIconDownloadDocumentsSummary: React.SFC<SvgIconProps> = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 34} height={props.height || 44} className={props.className} onClick={props.onClick} viewBox="0 0 34 44" role="img">
        <path fill={props.fillColor || "#FFFFFF"} d="M30.98,35.939c1.396-0.459,2.481-1.616,2.858-3.08l0.097-0.471C33.967,32.171,34,31.954,34,31.727V9.714 l-8.337-8.15c-1.232-1.25-3.247-1.286-3.474-1.286H10.214c-2.382,0-4.319,1.99-4.319,4.437v1.229H4.319 C1.938,5.945,0,7.893,0,10.289v26.293c0,2.396,1.938,4.347,4.319,4.347h14.569v2.793h14.167v-3.778h-5.795L30.98,35.939z" />
        <path fill={props.secondaryColor || "#0973BA"} d="M29.416,33.333v-7.555h-6.611v7.555h-2.832l6.138,6.61l6.139-6.61H29.416z M19.973,40.89h12.276v1.889 H19.973V40.89z" />
        <rect fill={props.primaryColor || "#8BC656"} x="13.687" y="16.973" width="12.604" height="1.97" />
        <polygon fill={props.primaryColor || "#8BC656"} points="13.69,25.506 19.554,25.506 21.189,23.537 13.687,23.537 " />
        <rect fill={props.primaryColor || "#8BC656"} x="13.687" y="11.724" width="6.633" height="1.968" />
        <path fill={props.primaryColor || "#8BC656"} d="M2.868,36.359V10.472c0-0.454,1.298-1.768,1.747-1.768V6.889c-1.952,0-3.531,1.605-3.531,3.583v25.887 c0,1.981,1.58,3.584,3.531,3.584h12.344v-1.817H4.614C4.166,38.126,2.868,36.816,2.868,36.359z" />
        <path fill={props.primaryColor || "#8BC656"} d="M25.129,2.227c-0.979-0.992-2.726-1.005-2.801-1.005H10.352c-1.86,0-3.375,1.568-3.375,3.493v27.011 c0,1.929,1.515,3.496,3.375,3.496h5.878l-1.072-1.854h-4.807c-0.875,0-1.582-0.735-1.582-1.643V4.715 c0-0.905,0.707-1.642,1.582-1.642h11.651v4.761c0,2.083,1.674,3.777,3.744,3.777h5.652v12.393h0.002l1.793,1.007V10.112 L25.129,2.227z M25.747,9.722c-1.033,0-1.873-0.847-1.873-1.888V3.552l6.313,6.17H25.747z" />
    </svg>;
}



//Other icons

export const SvgIconSendManually: React.SFC<SvgIconProps> = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 130.664} height={props.height || 75} className={props.className} onClick={props.onClick} viewBox="0 0 130.664 75" role="img">
        <rect fill={props.secondaryColor || "#0973BA"} width="130.664" height="75" />
        <rect x="4.154" y="4.156" fill={props.fillColor || "#FEFEFE"} width="122.352" height="66.686" />
        <path fill={props.secondaryColor || "#0973BA"} d="M23.189,30.907c-6.3,0-11.429-5.127-11.429-11.43S16.89,8.049,23.189,8.049 c6.301,0,11.43,5.126,11.43,11.429S29.49,30.907,23.189,30.907L23.189,30.907z M23.189,11.579c-4.354,0-7.896,3.541-7.896,7.898 c0,4.355,3.541,7.896,7.896,7.896c4.355,0,7.896-3.541,7.896-7.896C31.086,15.12,27.545,11.579,23.189,11.579L23.189,11.579z" />
        <circle fill={props.secondaryColor || "#0973BA"} cx="23.189" cy="19.48" r="5.833" />
        <rect x="10.389" y="43.832" fill={props.secondaryColor || "#0973BA"} width="48.453" height="4.675" />
        <rect x="10.389" y="51.638" fill={props.secondaryColor || "#0973BA"} width="42.287" height="4.675" />
        <rect x="10.389" y="59.44" fill={props.secondaryColor || "#0973BA"} width="48.453" height="4.675" />
        <path fill={props.secondaryColor || "#0973BA"} d="M104.365,13.032v7.114c-2.276,1.101-5.37,1.214-6.758,1.226c-2.37-0.015-5.82-1.081-9.157-2.112 c-3.398-1.049-6.607-2.041-9.019-2.085c-2.728-0.135-7.719,1.18-9.837,3.967c-0.415,0.55-0.313,1.33,0.238,1.747 c0.545,0.419,1.328,0.314,1.746-0.238c1.428-1.88,5.636-2.983,7.803-2.983c2.063,0.038,5.249,1.024,8.331,1.974 c3.511,1.087,7.141,2.207,9.874,2.226c0.062,0.003,0.116,0.003,0.178,0.003c1.725,0,4.292-0.188,6.601-1.029v2.235 c-2.276,1.103-5.37,1.216-6.758,1.229c-2.37-0.017-5.82-1.083-9.157-2.115c-3.398-1.049-6.607-2.041-9.019-2.083 c-2.728-0.137-7.719,1.177-9.837,3.964c-0.415,0.55-0.313,1.332,0.238,1.748c0.545,0.418,1.328,0.314,1.746-0.238 c1.428-1.879,5.636-2.982,7.803-2.982c2.063,0.037,5.249,1.024,8.331,1.975c3.511,1.085,7.141,2.205,9.874,2.223 c0.062,0.003,0.116,0.003,0.178,0.003c1.725,0,4.292-0.186,6.601-1.026v2.235c-2.276,1.1-5.37,1.213-6.758,1.229 c-2.37-0.02-5.82-1.086-9.157-2.115c-3.398-1.047-6.607-2.039-9.019-2.084c-2.728-0.134-7.719,1.177-9.837,3.964 c-0.415,0.55-0.313,1.33,0.238,1.747c0.545,0.422,1.328,0.314,1.746-0.235c1.428-1.88,5.636-2.982,7.803-2.982 c2.063,0.036,5.249,1.022,8.331,1.973c3.511,1.085,7.141,2.205,9.874,2.226c0.062,0,0.116,0,0.178,0 c1.725,0,4.292-0.186,6.601-1.027v2.215h15.133V13.032H104.365z M105.613,22.299c0.729-0.371,1.41-0.818,1.99-1.379V24 c-0.49-0.433-1.229-0.436-1.697,0.029c-0.092,0.09-0.193,0.172-0.293,0.255V22.299z M105.613,27.23 c0.729-0.372,1.41-0.819,1.99-1.381v3.078c-0.49-0.432-1.229-0.434-1.697,0.035c-0.092,0.09-0.193,0.172-0.293,0.255V27.23z M118.25,33.671h-12.637V32.16c0.729-0.371,1.41-0.819,1.99-1.38v0.292h8.656V16.875h-8.656v2.194 c-0.49-0.432-1.229-0.434-1.697,0.032c-0.092,0.09-0.193,0.172-0.293,0.255v-5.078h12.637V33.671z" />
    </svg>;
}


export const SvgIconDownloadAll: React.SFC<SvgIconProps> = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 34} height={props.height || 44} className={props.className} onClick={props.onClick} viewBox="0 0 34 44" role="img">
        <path fill={props.fillColor || "#FFFFFF"} d="M30.98,35.939c1.396-0.459,2.481-1.616,2.858-3.08l0.097-0.471C33.967,32.171,34,31.954,34,31.727V9.714 l-8.337-8.15c-1.232-1.25-3.247-1.286-3.474-1.286H10.214c-2.382,0-4.319,1.99-4.319,4.437v1.229H4.319 C1.938,5.945,0,7.893,0,10.289v26.293c0,2.396,1.938,4.347,4.319,4.347h14.569v2.793h14.167v-3.778h-5.795L30.98,35.939z" />
        <path fill={props.secondaryColor || "#0973BA"} d="M29.416,33.333v-7.555h-6.611v7.555h-2.832l6.138,6.61l6.139-6.61H29.416z M19.973,40.89h12.276v1.889 H19.973V40.89z" />
        <rect fill={props.primaryColor || "#8BC656"} x="13.687" y="16.973" width="12.604" height="1.97" />
        <polygon fill={props.primaryColor || "#8BC656"} points="13.69,25.506 19.554,25.506 21.189,23.537 13.687,23.537 " />
        <rect fill={props.primaryColor || "#8BC656"} x="13.687" y="11.724" width="6.633" height="1.968" />
        <path fill={props.primaryColor || "#8BC656"} d="M2.868,36.359V10.472c0-0.454,1.298-1.768,1.747-1.768V6.889c-1.952,0-3.531,1.605-3.531,3.583v25.887 c0,1.981,1.58,3.584,3.531,3.584h12.344v-1.817H4.614C4.166,38.126,2.868,36.816,2.868,36.359z" />
        <path fill={props.primaryColor || "#8BC656"} d="M25.129,2.227c-0.979-0.992-2.726-1.005-2.801-1.005H10.352c-1.86,0-3.375,1.568-3.375,3.493v27.011 c0,1.929,1.515,3.496,3.375,3.496h5.878l-1.072-1.854h-4.807c-0.875,0-1.582-0.735-1.582-1.643V4.715 c0-0.905,0.707-1.642,1.582-1.642h11.651v4.761c0,2.083,1.674,3.777,3.744,3.777h5.652v12.393h0.002l1.793,1.007V10.112 L25.129,2.227z M25.747,9.722c-1.033,0-1.873-0.847-1.873-1.888V3.552l6.313,6.17H25.747z" />
    </svg>;
}


export const SvgIconManualSignature: React.SFC<SvgIconProps> = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 64} height={props.height || 45} className={props.className} onClick={props.onClick} viewBox="0 0 64 45" role="img">
        <path fill={props.primaryColor || "#8BC656"} d="M13.559,44.802c-1.014,0-1.877-0.271-2.569-0.82c-1.539-1.22-1.88-3.598-0.934-6.522 c0.125-0.388,0.15-0.613,0.151-0.729c-1.042-0.177-5.134,1.954-8.98,4.72l-1.056-1.656c3.361-2.417,9.294-6.232,11.249-4.643 c0.721,0.586,0.861,1.581,0.416,2.957c-0.652,2.019-0.548,3.614,0.279,4.271c1.163,0.922,5.012,1.264,10.103-2.591l1.623,1.495 C20.25,44.001,15.858,44.801,13.559,44.802L13.559,44.802z" />
        <path fill={props.secondaryColor || "#0973BA"} d="M27.108,31.27l6.425,6.254L24.542,40L27.108,31.27z M57.263,1.494l6.104,6.081 c0.844,0.838,0.844,2.199,0,3.04L37.404,36.606l-2.378,0.507l-7.456-7.425l0.701-2.366l10.708-10.565l1.485,1.477L52.688,6.058 l6.101,6.075l1.525-1.522l-7.633-7.595l1.53-1.521C55.053,0.654,56.418,0.654,57.263,1.494z M49.617,0l3.054,3.039L40.46,15.194 l0.045-6.12L49.617,0z" />
    </svg>;
}

export const SvgIconSendElectronically: React.SFC<SvgIconProps> = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 108} height={props.height || 41} className={props.className} onClick={props.onClick} viewBox="0 0 108 41" role="img">
        <path fill={props.fillColor || "#FEFEFE"} d="M107.205,29c0,1.656-1.343,3-3,3H3.795c-1.657,0-3-1.344-3-3V4.277c0-1.657,1.343-3,3-3h100.41 c1.657,0,3,1.343,3,3V29z" />
        <path fill={props.primaryColor || "#8BC656"} d="M104,32h-2.998v-2H104c1.104,0,2-0.896,2-2V4c0-1.103-0.896-2-2-2H4C2.897,2,2,2.897,2,4v24 c0,1.104,0.897,2,2,2h74.293v2H4c-2.206,0-4-1.794-4-4V4c0-2.206,1.794-4,4-4h100c2.206,0,4,1.794,4,4v24 C108,30.206,106.206,32,104,32L104,32z" />
        <path fill={props.primaryColor || "#8BC656"} d="M38.036,19.238c0-0.53-0.197-0.937-0.591-1.221c-0.394-0.284-1.103-0.584-2.127-0.899 s-1.835-0.625-2.433-0.93c-1.628-0.838-2.442-1.964-2.442-3.381c0-0.735,0.218-1.392,0.655-1.97 c0.437-0.574,1.064-1.025,1.881-1.349C33.796,9.162,34.714,9,35.731,9c1.025,0,1.937,0.176,2.739,0.529 c0.801,0.352,1.423,0.85,1.866,1.492c0.443,0.643,0.665,1.373,0.665,2.19h-2.955c0-0.624-0.207-1.109-0.62-1.455 c-0.414-0.346-0.995-0.52-1.744-0.52c-0.722,0-1.283,0.146-1.684,0.436c-0.4,0.29-0.6,0.672-0.6,1.146 c0,0.443,0.234,0.814,0.704,1.114c0.469,0.3,1.16,0.58,2.073,0.842c1.68,0.48,2.905,1.076,3.673,1.787 C40.616,17.273,41,18.16,41,19.22c0,1.18-0.47,2.104-1.408,2.775C38.652,22.664,37.389,23,35.8,23 c-1.103,0-2.107-0.191-3.013-0.576c-0.906-0.383-1.597-0.908-2.073-1.576C30.238,20.18,30,19.406,30,18.527h2.964 c0,1.503,0.946,2.255,2.836,2.255c0.703,0,1.251-0.136,1.645-0.407S38.035,19.725,38.036,19.238L38.036,19.238z M51.976,16.933 h-5.902v3.75H53V23H43V9h9.98v2.337h-6.906v3.336h5.902V16.933z M66,23h-2.787l-5.426-9.211V23H55V9h2.787l5.436,9.231V9H66V23z M68,23V9h4.428c1.266,0,2.396,0.277,3.396,0.832c0.996,0.554,1.775,1.343,2.337,2.365S79,14.381,79,15.683v0.644 c0,1.301-0.275,2.458-0.825,3.471c-0.55,1.013-1.325,1.798-2.327,2.356c-1.002,0.558-2.132,0.84-3.391,0.846H68z M70.965,11.337 v9.346h1.434c1.16,0,2.045-0.369,2.658-1.106c0.612-0.737,0.926-1.792,0.939-3.164v-0.74c0-1.423-0.305-2.502-0.91-3.236 c-0.605-0.733-1.492-1.1-2.658-1.1H70.965z" />
        <path fill={props.secondaryColor || "#0973BA"} d="M84.023,13h1.971v2.002h2.007v1.999h2v1.982h1.993v2.014h2v1.987h2v2.012h1.994v1.99H100v2.005h-5.998v4.026 h1.984v3.971h2.005V41h-3.992v-3.999h-2.004v-3.996h-2.002v-2.008h-1.972v1.979h-2.022v2.013H84L84.023,13z" />
    </svg>;
}


export const SvgIconSignCompleted: React.SFC<SvgIconProps> = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 14}
        height={props.height || 16}
        className={props.className} viewBox="0 0 14 16"
        role="img"
        >
        <g fill="none" fillRule="evenodd">
            <g fill="#8BC656" fillRule="nonzero">
                <g>
                    <g>
                        <path d="M9.973 2L10 3 7 1H1v12h9v1H1.478C.662 14 0 13.815 0 13V1c0-.815.662-1 1.478-1h5.655l2.84 2z" />
                    </g>
                </g>
                <path d="M12.535 4.444C12.25 4.158 11.862 4 11.446 4c-.419 0-.807.16-1.09.443L7.33 7.31 6.183 8.406 4.648 6.963c-.287-.29-.675-.449-1.093-.449-.418 0-.806.16-1.085.44-.303.274-.47.645-.47 1.045 0 .4.167.771.465 1.042l2.622 2.506c.27.288.67.453 1.098.453.427 0 .827-.165 1.09-.444L12.53 6.53c.303-.275.47-.645.47-1.046 0-.398-.166-.767-.465-1.04z" />
            </g>
        </g>
    </svg>
}
