import * as React from 'react';
import CheckboxTree from 'react-checkbox-tree';
import { ELDocLabel, ELDocType, ElDocument, IElDocument } from '../../../core/domain/models/esign/Document';
import { IDocumentType } from '../../../core/domain/viewModels/IDocumentSignatureDataViewModel';
import {
    SvgIconSignCompleted
} from '../../Common/Icons/SvgIcons';
import { Guid } from 'src/core/utilities/Guid';
export interface FileListProps {
    selectedDocumentId: number;
    onFileClick(engagementDocument: IElDocument, pageNo: number, isAdditionalDocument: boolean): void;
    selectedPageNo: number,
    elDocuments: IElDocument[];
    documentType: ELDocType;
}

export interface FileListState {
    expanded: string[],
    nodes: any[]
}

const { EL, AdditionalEsign, OrganizerSignature } = ELDocLabel;

export class FileList extends React.Component<FileListProps, FileListState> {


    constructor(props: FileListProps) {
        super(props);

        this.state = {

            expanded: ['-1'],
            nodes: []
        }
    }

    static getDerivedStateFromProps(nextProps: FileListProps, nextState: FileListState) {
        return {
            expanded: nextState.expanded.indexOf("-1") > -1 ? FileList.createExpandedNodes() : nextState.expanded,
            nodes: FileList.createTreeNodes(nextProps)
        };
    }

    onExpand = (expanded: any) => {
        this.setState({ expanded });
    }

    static getDocumentTypeLabel = (value: string) => {
        if (value === 'ConsentForm')
            return 'Consent Form';
        else if (value === 'Additional_EngagementLetter')
            return 'Engagement Letter';
        else if (value === 'organizerWithSignatureDocument')
            return 'Organizer With Signature Document';
        else
            return 'Others';
    }

    private static createTreeNodes = (props: FileListProps): any[] => {

        let nodes: any[] = [];
        const engagementLetterDocument = props?.elDocuments?.length > 0 ? props?.elDocuments?.filter(x => x.type == ELDocType.EL)[0] : ElDocument.createNullObject();
        
        if (engagementLetterDocument != undefined && engagementLetterDocument?.id > 0) {
            const elSignCompleted = engagementLetterDocument?.signCompleted;
            const documentId = engagementLetterDocument.id;
            let tmpNode: any = {
                value: EL,
                label: FileList.getNodeLabel(EL, 'parentTreeNode'),
                showcheckbox: false,
                title: EL,
                children: []
            }
            const value = { id: documentId, pageNo: 1, guid: Guid.newGuid() };
            tmpNode.children.push({
                value: JSON.stringify(value),
                label: FileList.getNodeLabel(EL, 'childTreeNode'),
                showcheckbox: false,
                icon: elSignCompleted ? FileList.getSignCompletedIcon() : FileList.getPdfIcon(false),
                title: EL,
                className: "file-node-" + documentId + "_" + ELDocType.EL + "_1",
                id: "file-node-" + documentId + "_" + ELDocType.EL + "_1",
            });
            nodes.push(tmpNode);
        }

        var additionalEsign = props?.elDocuments?.filter(x => x.type == ELDocType.Additional);

        if (additionalEsign != undefined && additionalEsign.length > 0) {
            let tmpNode: any = {
                value: AdditionalEsign,
                label: FileList.getNodeLabel(AdditionalEsign, 'parentTreeNode'),
                showcheckbox: false,
                title: AdditionalEsign,
                children: []
            };
            for (var i = 0; i <= additionalEsign.length - 1; i++) {
                const document: IElDocument = additionalEsign[i];
                const signCompleted = document?.signCompleted;
                const value = { id: document.id, pageNo: 1, guid: Guid.newGuid() };
                const fileName = document?.fileName?.replace(/(.*)\.(.*?)$/, "$1")
                tmpNode.children.push({
                    disabled: false,
                    value: JSON.stringify(value),
                    label: FileList.getNodeLabel(fileName + "_" + FileList.getDocumentTypeLabel(IDocumentType[document.documentType]), 'childTreeNode'),
                    showcheckbox: false,
                    icon: signCompleted ? FileList.getSignCompletedIcon() : FileList.getPdfIcon(false),
                    title: fileName + "_" + FileList.getDocumentTypeLabel(IDocumentType[document.documentType]),
                    className: "file-node-" + document.id + "_" + ELDocType.Additional + "_1",
                    id: "file-node-" + document.id + "_" + ELDocType.Additional + "_1",
                });
            }
            nodes.push(tmpNode);
        }
        
        const organizerWithSignDocument = props?.elDocuments?.length > 0 ? props?.elDocuments?.filter(x => x.type == ELDocType.OrganizerSignDocument)[0] : ElDocument.createNullObject();
        
        if (organizerWithSignDocument != undefined && organizerWithSignDocument?.id > 0) {
            const orgSignCompleted = organizerWithSignDocument?.signCompleted;
            const documentId = organizerWithSignDocument.id;
            let tmpNode: any = {
                value: OrganizerSignature,
                label: FileList.getNodeLabel(OrganizerSignature, 'parentTreeNode'),
                showcheckbox: false,
                title: OrganizerSignature,
                children: []
            }
            const value = { id: documentId, pageNo: 1, guid: Guid.newGuid() };
            tmpNode.children.push({
                value: JSON.stringify(value),
                label: FileList.getNodeLabel(OrganizerSignature, 'childTreeNode'),
                showcheckbox: false,
                icon: orgSignCompleted ? FileList.getSignCompletedIcon() : FileList.getPdfIcon(false),
                title: OrganizerSignature,
                className: "file-node-" + documentId + "_" + ELDocType.OrganizerSignDocument + "_1",
                id: "file-node-" + documentId + "_" + ELDocType.OrganizerSignDocument + "_1",
            });
            nodes.push(tmpNode);
        }

        return nodes;
    }

    static getNodeLabel = (lable: string, clasValue: string) => {
        return <span title={lable} className={clasValue}> {lable} </span>
    }

    static getPdfIcon(showCheck: boolean) {
        return <i className="fa fa-file-pdf-o" style={{ color: showCheck ? 'green' : '#bbb' }} />
    }
    static getPageIcon(showCheck: boolean) {
        return <i className="fa fa-file-o" style={{ color: showCheck ? 'green' : '#bbb' }} />
    }

    static getSignCompletedIcon() {

        return <SvgIconSignCompleted />
    }

    static getTickIcon(showCheck: boolean) {

        return <span className="rct-icon rct-icon-check" style={{ color: showCheck ? 'green' : '#bbb' }} />
    }

    private static createExpandedNodes = (): any[] => {
        let expandedNodes: string[] = [];
        expandedNodes.push(AdditionalEsign);
        expandedNodes.push(EL);
        expandedNodes.push(OrganizerSignature);
        return expandedNodes;
    }

    private handleFileClick = (node: any) => {

        if (node.children == undefined) {
            const value = JSON.parse(node.value);
            let docType = ELDocType.EL;
            switch (node.parent.title) {
                case EL:
                    docType = ELDocType.EL;
                    break;
                case AdditionalEsign:
                    docType = ELDocType.Additional;
                    break;
                case OrganizerSignature:
                    docType = ELDocType.OrganizerSignDocument;
                    break;
            }
            var selectedDocument = this.props.elDocuments.filter(x => x.id === parseInt(value.id) && x.type == docType)[0];
            if (node.parent.title == EL || node.parent.title == OrganizerSignature) {
                selectedDocument && this.props.onFileClick(selectedDocument, parseInt(value.pageNo), false)
            }
            else {
                selectedDocument && this.props.onFileClick(selectedDocument, parseInt(value.pageNo), true)
            }

        }
    }

    private highlightSelectedFile(fileId: number, pageNo: number, type: ELDocType) {

        const currentfileitem: any = document.getElementsByClassName("file-node-selected")[0];
        currentfileitem && currentfileitem.classList.remove("file-node-selected");

        const nextFileitem: any = document.getElementsByClassName("file-node-" + fileId + "_"  + type + "_" + pageNo)[0];
        nextFileitem && nextFileitem.classList.add("file-node-selected");
    }

    public render() {

        const { nodes, expanded } = this.state;

        setTimeout(() => {
            this.highlightSelectedFile(this.props.selectedDocumentId, this.props.selectedPageNo, this.props.documentType);
        }, 0)



        return (<div className="file-list-tree-view-container">

            <CheckboxTree
                showExpandAll={false}
                expanded={expanded}
                iconsClass="fa5"
                nodes={nodes}
                expandOnClick
                data-test-auto="BBF1C5F3-8CA3-4DE7-AA6E-6C4FF6CA46C4"
                onClick={this.handleFileClick}
                onExpand={this.onExpand}
            />
        </div>);

    }
}
