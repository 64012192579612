import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { injectable } from "inversify";
import { UrlConstants } from "../../common/Constants";
import { IDataService } from "./abstraction/IDataService";
import { history } from "./History";
import { ErrorStatus } from "src/store/CoverPage/CoverPageStore";
import { container } from "src/startup/inversify.config";
import { ILoader } from "src/core/utilities/ui/Loader";
import { TYPES } from "src/startup/types";
import { IDialogBox } from "src/core/utilities/ui/DialogBox";
import { OrganizerStatusChangedWarning, SessionKey } from "src/components/Common/Constants";
import { GetDataFromStorage, SetDataToStorage } from "src/components/Helper/HelperFunction";
import { StatusCode } from "src/core/domain/models/Organizer/Organizer";

export const inMemoryToken: Map<string, string> = new Map();
const API_BASE_URL = process.env.REACT_APP_API_URL || "";

@injectable()
export class AxiosDataService implements IDataService {
	private _baseUri: string = API_BASE_URL;

	constructor(clientId?: string, initializeResponseInterceptor: boolean = true) {
		this.configureAuthorizationHeader(clientId);
		this.setupRequestInterceptor();
		if (initializeResponseInterceptor) {
			this.setupResponseInterceptor();
		}
	}

	private configureAuthorizationHeader(clientId?: string): void {
		if (clientId && inMemoryToken.has(clientId)) {
			axios.defaults.headers.common["Authorization"] = `Bearer ${inMemoryToken.get(clientId)}`;
		}
		axios.defaults.withCredentials = true;
	}

	private setupRequestInterceptor(): void {
		axios.interceptors.request.use(
			(config: any) => {
				config.withCredentials = true;
				return config;
			},
			(error: any) => Promise.reject(error)
		);
	}

	private setupResponseInterceptor(): void {
		axios.interceptors.response.use(
			(response: any) => {
				if (response.status === 401) {
					history.push("/invalid");
				}
				return response;
			},
			(error: any) => {
				const data = this.parseErrorResponseData(error);
				if (error?.response?.status === 410) {
					return this.handleGoneError(error, data);
				} else if (error?.response?.status === 302) {
					return this.handleRedirectError(error);
				} else if (this.shouldRetryRequest(error)) {
					return this.handleServerError(error);
				}
				else if (error?.response?.status === 419) {
					const redirectUrl = error?.response?.data;
					if (redirectUrl) {
						window.location.href = redirectUrl;
					  }
				}
				return Promise.reject(error);
			}
		);
	}

	private parseErrorResponseData(error: any): any {
		if (error?.response?.config?.responseType === 'arraybuffer') {
			const textDecoder = new TextDecoder('utf-8');
			const jsonString = textDecoder.decode(new Uint8Array(error?.response?.data));
			return JSON.parse(jsonString);
		}
		return error?.response?.data;
	}

	private RemoveEventListener() {
		window.onbeforeunload = null;
		window.removeEventListener('beforeunload', () => { });
	}

	private handleGoneError(error: any, data: any): Promise<void> {
		var isPopUpOpen = GetDataFromStorage(SessionKey.RefreshPopUpOpen);

		this.RemoveEventListener();
		if ([ErrorStatus.ClosedByFirm, ErrorStatus.OrganizerDeleted].includes(data?.errorCode)) {
			const loader = container ? container.get<ILoader>(TYPES.ILoader) : null;
			const dialogBox = container ? container.get<IDialogBox>(TYPES.IDialogBox) : null;
			loader?.hide();
			if (isPopUpOpen === "" && dialogBox) {
				SetDataToStorage(SessionKey.RefreshPopUpOpen, "true");
				dialogBox.refreshAlert(OrganizerStatusChangedWarning, () => {
					this.RemoveEventListener();
					history.push(`${UrlConstants.Error}${data?.errorCode}`);
				});
			}
		}
		error.response.status = StatusCode.OrganizerUnavailable;
		return Promise.reject(error);
	}

	private handleRedirectError(error: any): Promise<void> {
		if (error.response?.data === "invalidlink") {
			history.push("/Invalidlink");
			return Promise.reject("Invalid page redirection occurred.");
		}
		return Promise.resolve();
	}

	private shouldRetryRequest(error: any): boolean {
		return error.config?.__isRetryRequest !== undefined && !error.config?.__isRetryRequest;
	}

	private handleServerError(error: any): void {
		if ([403, 500].includes(error.response.status)) {
			history.push("/invalid", {
				statusCode: error.response.status,
				statusText: error.response.statusText,
				message: error.response.data.error,
			});
		} else if (error.response.data && error.response.data.length > 0) {
			error.response.data.forEach((message: any) => console.error(message.ErrorMessage));
		}
		error.config.__isRetryRequest = true;
	}

	set(baseUri: string): void {
		this._baseUri = baseUri;
	}

	getPaged<T>(page: number, uriPart?: string): Promise<AxiosResponse<T>> {
		return axios.get<T>(this.getFormattedUri(uriPart));
	}

	get<T>(uriPart?: string, data?: any, disableCache: boolean = false): Promise<AxiosResponse<T>> {
		if (disableCache) {
			axios.defaults.headers["Pragma"] = "no-cache";
		}
		return axios.get<T>(this.getFormattedUri(uriPart), data);
	}

	post<T>(uriPart: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
		return axios.post<T>(this.getFormattedUri(uriPart), data, config);
	}

	postJson<T>(data: any, uriPart?: string, config?: any): Promise<AxiosResponse<T>> {
		return axios.post<T>(this.getFormattedUri(uriPart), data, {
			headers: { "Content-Type": "application/json;utf-8" },
		});
	}

	put<T>(uriPart: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
		return axios.put<T>(this.getFormattedUri(uriPart), data, config);
	}

	putJson<T>(data: any, uriPart?: string, config?: any): Promise<AxiosResponse<T>> {
		return axios.put<T>(this.getFormattedUri(uriPart), data, {
			headers: { "Content-Type": "application/json;utf-8" },
		});
	}

	delete(id: number, uriPart?: string): Promise<void> {
		return axios.delete(this.getFormattedUri(uriPart));
	}

	deleteExtended(data: any, uriPart?: any): void {
		throw new Error("Method not implemented.");
	}

	private getFormattedUri(uriPart?: string): string {
		if (this._baseUri.endsWith("/") && uriPart?.startsWith("/")) {
			return `${this._baseUri}${uriPart.substring(1)}`;
		}
		return `${this._baseUri}${uriPart}`;
	}
}

export function initializeAxios(clientId?: string, initializeResponseInterceptor: boolean = true): AxiosDataService {
	var axiosDataService = new AxiosDataService(clientId, initializeResponseInterceptor);
	return axiosDataService;
}

export function storeTokenInMemory(clientId: string, token: string): void {
	inMemoryToken.set(clientId, token);
}