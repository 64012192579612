
export enum ClientType {
    Undefined = 0,
    Taxpayer,
    Spouse,
    Partner,
    Partnership,
    Shareholder
}

export enum EngagementType {
    None,
    E1040 = 1,
    E1065,
    E1120,
    E1120S,
    E1041,
    E1040NR
}

export enum EngagementTypeS {
    None = "None",
    E1040 = "E1040",
    E1065 = "E1065",
    E1120 = "E1120",
    E1120S = "E1120S",
    E1041 ="E1041"
}

export enum DocumentStatus {
    None = 0,
    UPLOADED,
    ERROR,
    READY,
    PROCESSING,
    REVIEW,
    USERSIGNED,
    DELIVERED,
    APPROVEDFORDELIVERY,
    PREPARINGFORDELIVERY,
    DELIVERYFAILED,
    NONSUPPORTED,
    RECALLED,
    DELETED
}

export enum SignatureStatus {
    None = 0,
    ESigned,
    ManuallySigned,
    Uploaded,
    AwaitingESign,
    AwaitingUpload,
    Locked,
    MailOrFax,
    SignedAndESigned,
    AutoParsed,
    Processing,
    Delivering,
    Delivered,
    DeliveryFailed
}

export enum SignatureType {
    None,
    ESign = 1,
    ESignWhenAllIncludedReturnsAllowed,
    ManualSign
}

export enum KBAMode {
    Test = 0,
    Live = 1
}

export enum KBATransactionResultType {
    passed,
    failed,
    error,
    questions,
    accepted,
    retryExceeded
}

export enum Role {
    None = 0,
    Taxpayer,
    Spouse,
    Partner,
    Partnership,
    Shareholder,
    CPA
}

export enum ReviewStatus {
    None,
    SentForReview,
    PendingReview,
    Reviewed,
    Ignored
}