import * as Moment from 'moment';
import { ISessionStore } from '../../core/utilities/LocalStore';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import { OrganizerConstants, OrganizerStatusChangedWarning, PreviewConstants, SourceDocumentConstants } from '../Common/Constants';
import { OrganizerMetadata, UploadedDocument } from '../../core/domain/models/Organizer/Organizer';
import { UploadedFile } from '../../core/domain/models/SourceDocument/SourceDocument';
import { IDialogBox } from 'src/core/utilities/ui/DialogBox';

export function GetSessionStore(): ISessionStore {
    return container.get<ISessionStore>(TYPES.ISessionStore);
}

export function GetFormatedDateTime(date: Date) {
    if (isNaN(date.getTime())) {
        throw new Error('Invalid date');
    }
    return Moment.utc(date).local().format('MM/DD/YYYY hh:mm A');
}

export function IsPreviewMode(organizerMetaDataInput: any) {
    const organizerMetadata: OrganizerMetadata = (organizerMetaDataInput as any).organizerMetadata;
    return organizerMetadata.isPreview == true;
}

export function IsPreviewModeFromCache() {
    const value = GetSessionStore().get(PreviewConstants.IsPreviewMode);
    return (value == null) ? false : StringtoBoolean(value);
}

export function RemoveEventListeners() {
    window.onbeforeunload = null;
    window.removeEventListener('beforeunload', () => { });
}

export function RedirectToDashBoard(clientId: any, dialogBox: IDialogBox) {
    RemoveEventListeners();
    dialogBox.refreshAlert(OrganizerStatusChangedWarning, () => {
        RemoveEventListeners();
        window.location.href = OrganizerConstants.OrganizerSummaryPageURL + clientId;
    });
}

export function IsClientViewFromCache() {
    const value = GetSessionStore().get(PreviewConstants.IsClientView);
    return (value == null) ? false : StringtoBoolean(value);
}

export function SetPreviewModeToCache(isPriview: boolean) {
    GetSessionStore().set(PreviewConstants.IsPreviewMode, isPriview);
}

export function SetClientViewToCache(isClientView: boolean) {
    GetSessionStore().set(PreviewConstants.IsClientView, isClientView);
}

export function AddDeletedFilesToStorage(uploadedDocuments: UploadedFile[], deletedFileId: number) {
    const existingList = GetSessionStore().get(SourceDocumentConstants.Uploaded);
    const deletedFile = uploadedDocuments.find(a => a.id == deletedFileId);
    if (deletedFile) {
        //If added file, do not add in deleted list
        const addedList = GetSessionStore().get(SourceDocumentConstants.Added);
        if (addedList) {
            const _list = addedList.split(':');
            const reqIndex = _list.indexOf(deletedFile.name);
            if (reqIndex != -1) {
                _list.splice(reqIndex, 1);
                if (_list.length == 0) {
                    GetSessionStore().remove(SourceDocumentConstants.Added);
                }
                else {
                    GetSessionStore().set(SourceDocumentConstants.Added, _list.join(':'));
                }
                return;
            }
        }
        //If uploaded and notified already , add to deleted list
        if (existingList && existingList != "" &&
            existingList.split(',').indexOf(deletedFileId.toString()) != -1) {
            let deletedList = GetSessionStore().get(SourceDocumentConstants.Deleted);
            if (deletedList && deletedList != "") {
                let _list = deletedList.split(':');
                _list.push(deletedFile.name);
                GetSessionStore().set(SourceDocumentConstants.Deleted, _list.join(':'));
            }
            else {
                GetSessionStore().set(SourceDocumentConstants.Deleted, deletedFile.name);
            }

        }
    }
}

export function GetDeletedFilesFromStorage() {
    const deletedFiles = GetSessionStore().get(SourceDocumentConstants.Deleted);
    return (deletedFiles == null) ? "" : deletedFiles;
}

export function SetUploadedDocumentsListToStorage(uploadedDocuments: UploadedDocument[]) {
    let uploadedList: number[] = [];
    if (uploadedDocuments) {
        uploadedDocuments.forEach((doc) => {
            uploadedList.push(doc.id);
        });
    }
    GetSessionStore().set(SourceDocumentConstants.Uploaded, uploadedList);
}

export function AddUploadedFileToStorage(filename: string) {
    let existingList = GetSessionStore().get(SourceDocumentConstants.Added);
    if (existingList) {
        existingList += ":" + filename;
        GetSessionStore().set(SourceDocumentConstants.Added, existingList);
    }
    else {
        GetSessionStore().set(SourceDocumentConstants.Added, filename);
    }
}

export function SetDataToStorage(key: string, value: string) {
    GetSessionStore().set(key, value);
}

export function GetDataFromStorage(key: string) {
    const value = GetSessionStore().get(key);
    return (value == null) ? "" : value;
}

export function RemoveStorageData(key: string) {
    GetSessionStore().remove(key);
}

export function StringtoBoolean(value?: string): boolean {
    if (!value) {
        return false
    }

    switch (value.toLocaleLowerCase()) {
        case 'true':
        case '1':
            return true
        default:
            return false
    }
}

export function getFileSize(fileSizeInBytes: number) {
    let file = parseInt((fileSizeInBytes / 1024).toString(), 10);
    let fileSize = "";
    fileSize = file < 1024 ? (file + " KB").toString() : (parseInt((file / 1024).toString(), 10) + " MB").toString()
    return fileSize;
}