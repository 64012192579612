import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { DownloadableStatus, FormGroupStatus, OrganizerMetadata } from '../../core/domain/models/Organizer/Organizer';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import * as OrganizerMetadataStore from '../../store/Organizer/OrganizerMetadataStore';
import * as OrganizerStore from '../../store/Organizer/OrganizerStore';
import * as PreviewStore from '../../store/Organizer/PreviewStore'
import { OrganizerConstants, PreviewConstants, SourceDocumentConstants } from '../Common/Constants';
import {
    NotToShowSummaryPageSecondaryMessage,
    ShowSummaryPageSecondaryMessage,
    IsNullorEmpty,
    GetMenuSeperator,
    HandleRedirect,
    GetRedirectUrlBasedOnStatus,
    EnableDisableCustomQuestionBasedOnStatus,
    EnableDisableOrganizerBasedOnStatus,
    GetTooltipForOrganizer,
    EnableDisableUploadDocumentBasedOnStatus,
    EnableDisableContinueBasedOnStatus,
    ShowClickHereToContinueTextBasedOnStatus,
    HideClickHereToContinueTextBasedOnStatus,
    ShowClickHereToContinueTextDependOnStatus,
    ShowThanksMessageIfOrganizerCompletedAndDownloadable,
    DownloadOrganizerDocument,
    DownloadEngagementLetter,
    CheckStatusforDisplayingMenu } from '../Helper/ClientviewTpPreviewHelper';
import { Shimmer } from '../Common/Shimmer/Shimmer';
import { Link } from 'react-router-dom';
import { SetClientViewToCache, SetDataToStorage } from '../Helper/HelperFunction';
import { UploadedDocumentStoreState } from '../../store/UploadedDocument/UploadedDocumentStoreModels';
import { actionCreators as UploadedDocumentStore } from '../../store/UploadedDocument/UploadedDocumentStore';
import * as PreparerMessageStore from '../../store/Organizer/PreparerMessageStore';
import * as AccountStore from '../../store/Common/AccountStore';
import { IOrganizerService } from '../../core/Services/OrganizerService/OrganizerService';
import { ILoader } from '../../core/utilities/ui/Loader';
import { IDialogBox } from '../../core/utilities/ui/DialogBox';
import { IHeaderInfoViewModel } from '../../core/domain/viewModels/IHeaderInfoViewModel';
import  CompleteOrganizerIcon from "../../assets/images/icon-complete-organizer.svg";
import  DownloadOrganizerIcon from "../../assets/images/icon-download-organizer.svg";
import  SummarySignDocumentsIcon from "../../assets/images/icon-summary-sign-documents.svg";
import  DownloadDocumentIcon from "../../assets/images/icon-download-documents.svg";
import  UploadDocumentIcon from "../../assets/images/icon-upload-documents.svg";
var htmlencode = require('htmlencode');

export type ClientViewProps =
    {
        organizerMetadata: OrganizerMetadata,
        previewStatus: DownloadableStatus;
        uploadedDocumentStatus: UploadedDocumentStoreState,
        preparerMessageStore: PreparerMessageStore.OrganizerPreparerMessageState,
        headerInfo: IHeaderInfoViewModel,
    }
    & typeof OrganizerMetadataStore.actionCreators
    & typeof OrganizerStore.actionCreators
    & typeof PreviewStore.actionCreators
    & typeof UploadedDocumentStore
    & typeof PreparerMessageStore.actionCreators
    & typeof AccountStore.actionCreators
    & RouteComponentProps<{}>;

interface ClientViewState {
    organizerStatus: FormGroupStatus;
    engagementLetterStatus: FormGroupStatus;
    organizerMetadata: OrganizerMetadata;
    enabledSourceDocuments: boolean;
    signingOrder: number;
    engagementSignerStatus: FormGroupStatus;
    isEngagementControl: boolean;
    disableLinks: boolean;
    hasCustomQuestion: boolean;
    customQuestionStatus: FormGroupStatus;
}

const organizerService = container.get<IOrganizerService>(TYPES.IOrganizerService);
const loader = container.get<ILoader>(TYPES.ILoader);
const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);

export class ClientView extends React.Component<ClientViewProps, ClientViewState> {

    private _param: any;
    constructor(props: ClientViewProps) {

        super(props);

        this.state = {
            engagementLetterStatus: FormGroupStatus.NA,
            organizerStatus: FormGroupStatus.None,
            organizerMetadata: OrganizerMetadata.createNullObject(),
            enabledSourceDocuments: false,
            signingOrder: 1,
            engagementSignerStatus: FormGroupStatus.None,
            isEngagementControl: true,
            disableLinks: false,
            hasCustomQuestion: false,
            customQuestionStatus: FormGroupStatus.None
        }
    }

    componentDidMount() {
        this._param = this.props.match.params;
        this.props.requestOrganizerMetadata(this._param.clientId);
        this.getDocumentStatus();
        this.initiatePreviewDocumentsStatusPolling(0);
        this.props.requestUploadedDocumentStatus(this._param.clientId);
        this.props.requestOrganizerPreparerMessage(this._param.clientId);
        this.getDocumentStatus();
        this.props.requestHeaderInfo(this._param.clientId, false);
        SetClientViewToCache(true);
    }

    private initiatePreviewDocumentsStatusPolling = (retrycount: number) => {
        this.showLoader(retrycount);
        let timeout: number = 10000;
        if (retrycount > 10) {
            timeout = 60000;
        }
        if (retrycount == 16) {
            this.OnGeneratingDocsError(PreviewConstants.GeneratingPreviewTimeLimitExceededMsg);
            return;
        }
        switch (this.props.previewStatus) {
            case DownloadableStatus.Ready:
                loader.hide();
                break;
            case DownloadableStatus.Unknown:
                this.OnGeneratingDocsError(PreviewConstants.GeneratingPreviewFailedMessage);
                return;
            case DownloadableStatus.Preparing:
            default:
                loader.hide();
                break;
        }
    }

    private OnGeneratingDocsError = (message: string) => {
        this.setState({
            disableLinks: true
        });
        loader.hide();
        dialogBox.alert(message);
    }

    private showLoader = (retryCount: number) => {
        if (retryCount < 1) {
            loader.show();
        }
        else {
            loader.show(PreviewConstants.GeneratingPreviewMessage);
        }
    }

    private getDocumentStatus = () => {

        const _self = this;

        organizerService.getDocumentStatusAndSourceFileSettings(this._param.clientId).then(function (response: any) {

            _self.setState({
                engagementLetterStatus: response.data.engagementLetterStatus,
                organizerStatus: response.data.organizerStatus,
                enabledSourceDocuments: response.data.enabledSourceDocuments,
                signingOrder: response.data.client.signingOrder,
                engagementSignerStatus: response.data.engagementSignerStatus,
                isEngagementControl: response.data.isEngagementcontrol,
                hasCustomQuestion: response.data.hasCustomQuestion,
                customQuestionStatus: response.data.customQuestionStatus
            });
            SetDataToStorage(SourceDocumentConstants.IsSourceDocumentEnabled, _self.state.enabledSourceDocuments.toString());
        });
    }

    private getRedirectUrl = () => {

        const {
            engagementLetterStatus,
            signingOrder,
            engagementSignerStatus,
            isEngagementControl,
            hasCustomQuestion,
            customQuestionStatus } = this.state;

        return GetRedirectUrlBasedOnStatus(
            engagementLetterStatus,
            signingOrder,
            engagementSignerStatus,
            isEngagementControl,
            hasCustomQuestion,
            customQuestionStatus,
            this._param.clientId
        );
    }

    private getCustomQuestionsButton() {
        const {
            engagementSignerStatus,
            engagementLetterStatus,
            signingOrder,
            isEngagementControl,
            customQuestionStatus,
            organizerStatus } = this.state;
        let isDisableable: boolean = EnableDisableCustomQuestionBasedOnStatus(
                engagementSignerStatus,
                engagementLetterStatus,
                signingOrder,
                isEngagementControl,
                customQuestionStatus,
                organizerStatus);

        let redirectUrl = `${OrganizerConstants.CustomQuestionsURL}${this._param.clientId}`
        let questionnaireText = OrganizerConstants.SummaryPageCustomQuestionsText;
        let disableQuestionnaire = (
            customQuestionStatus == FormGroupStatus.QuestionnaireCompleted ||
            organizerStatus == FormGroupStatus.ManuallyCompleted
        );

        if (disableQuestionnaire) {
            redirectUrl = "";
            questionnaireText = OrganizerConstants.SummaryPageCustomQuestionsCompletedText;
        }

        return (<React.Fragment>
            <div data-test-auto='d50c459c-bbe3-11eb-8529-0242ac130005'
                title={isDisableable ? disableQuestionnaire ?
                    OrganizerConstants.CustomQuestionsCompleteCQDisabled :
                    OrganizerConstants.EsignPendingCQDisabled : ""}>

                <div className={isDisableable ? 'area-disabled' : ''}>
                    <Link to={redirectUrl} >
                        <img src={CompleteOrganizerIcon} />
                        <br />
                        <div className={"organizer-welcome-page-menu-icon-title"}>
                            {questionnaireText}
                        </div>
                    </Link>
                </div>
            </div> {GetMenuSeperator()}</React.Fragment>);
    }

    private getOrganizerButton() {
        const {
            enabledSourceDocuments,
            engagementSignerStatus,
            engagementLetterStatus,
            signingOrder,
            isEngagementControl,
            hasCustomQuestion,
            customQuestionStatus } = this.state;
        let redirectUrl: string = this.getRedirectUrl();

        const getIsDisabled = () => {
            return EnableDisableOrganizerBasedOnStatus(
                hasCustomQuestion,
                customQuestionStatus,
                engagementSignerStatus,
                engagementLetterStatus,
                signingOrder,
                isEngagementControl);
        };

        const isDisableable = getIsDisabled();

        return (<React.Fragment>
            <div data-test-auto='d50c459c-bbe3-11eb-8529-0242ac130003'
                title={GetTooltipForOrganizer(
                    customQuestionStatus,
                    engagementSignerStatus,
                    signingOrder,
                    isEngagementControl,
                    hasCustomQuestion,
                    engagementLetterStatus)}>
                <div className={isDisableable ? 'area-disabled' : ''}>
                    <Link to={redirectUrl}
                        onClick={(event) => { this.disableLink(event); }}>
                        <img src={CompleteOrganizerIcon} />
                        <br />
                        <div className={"organizer-welcome-page-menu-icon-title"}>
                            {OrganizerConstants.SummaryPageCompleteOrganizerText}
                        </div>
                    </Link>
                </div>

            </div> {enabledSourceDocuments && GetMenuSeperator()}</React.Fragment>);
    }

    private getOrganizerDownloadButton() {

        return (<React.Fragment><div onClick={DownloadOrganizerDocument}
            data-test-auto='cc0a0b59-3dfc-42e5-b683-3a1a3687b5dd'>
            <img src={DownloadOrganizerIcon} />
            <br />
            <div className={"organizer-welcome-page-menu-icon-title"}>
                {OrganizerConstants.SummaryPageDownloadOrganizerText}
            </div>
        </div> {this.state.enabledSourceDocuments && GetMenuSeperator()}</React.Fragment>);
    }

    private getDownloadEngagementButton() {

        const { organizerStatus } = this.state;

        return (<React.Fragment><div data-test-auto='d50c4b96-bbe3-11eb-8529-0242ac130003'
            onClick={DownloadEngagementLetter}>
            <img src={DownloadDocumentIcon} />
            <br />
            <div className={"organizer-welcome-page-menu-icon-title"}>
                {OrganizerConstants.SummaryPageDownloadEngagementLetterText}
            </div>
        </div> {organizerStatus != FormGroupStatus.None && GetMenuSeperator()}</React.Fragment>);
    }

    private getSignEngagementButton() {
        const { organizerStatus, engagementLetterStatus, signingOrder } = this.state;
        if (CheckStatusforDisplayingMenu(this._param?.clientId)) {
            let isEsignEnable: boolean = (signingOrder == 2 && (engagementLetterStatus == FormGroupStatus.AwaitingESign));
            return (<React.Fragment>
                <div data-test-auto='d50c44d4-bbe3-11eb-8529-0242ac130003' title={isEsignEnable ? "Awaiting first signer to complete" : ""}>

                    <div
                        className={isEsignEnable ? 'area-disabled' : ''}>
                        <Link to={"/engagementletter/sign/" + this._param.clientId}
                            onClick={(event) => { this.disableLink(event); }}>
                            <img src={SummarySignDocumentsIcon} />
                            <br />
                            <div className={"organizer-welcome-page-menu-icon-title"}>
                                {OrganizerConstants.SummaryPageSignEngagementLetterText}
                            </div>
                        </Link>
                    </div>
                </div> {organizerStatus != FormGroupStatus.None && GetMenuSeperator()}</React.Fragment >);
        }
    }

    private getCompletedUploadDocumentsButton() {
        return (<React.Fragment>
            <div data-test-auto='05f8ea7a-64bb-4413-819b-027f24d4f010'>
                <Link to={"/uploaddocuments/" + this._param.clientId} >
                    <img src={DownloadDocumentIcon} />
                    <br />
                    <div className={"organizer-welcome-page-menu-icon-title"}>
                        {OrganizerConstants.SummaryPageCompletedUploadDocumentsText}
                    </div>
                </Link>
            </div></React.Fragment>);
    }

    private getUploadDocumentsButton() {
        if (CheckStatusforDisplayingMenu(this._param?.clientId)) {

            const { engagementSignerStatus, engagementLetterStatus, signingOrder, isEngagementControl } = this.state;
            let isDisableable: boolean = EnableDisableUploadDocumentBasedOnStatus(
                    engagementSignerStatus,
                    engagementLetterStatus,
                    signingOrder,
                    isEngagementControl);

            return (<React.Fragment>
                <div data-test-auto='05f8ea7a-64bb-4413-819b-027f24d4f0c9'
                    title={isDisableable ? OrganizerConstants.EsignPendingUploadDocumentDisabled : ""}>
                    <div className={isDisableable ? 'area-disabled' : ''}>
                        <Link to={"/uploaddocuments/" + this._param.clientId}
                            onClick={(event) => { this.disableLink(event); }}>
                            <img src={UploadDocumentIcon} />
                            <br />
                            <div className={"organizer-welcome-page-menu-icon-title"}>
                                {OrganizerConstants.SummaryPageUploadDocumentsText}
                            </div>
                        </Link>
                    </div>

                </div></React.Fragment>);
        }
    }

    private disableLink = (event: any) => {
        if (this.state.disableLinks)
            event.preventDefault();
    }

    private getContinueButton() {

        const { engagementLetterStatus, organizerStatus, signingOrder, isEngagementControl } = this.state;

        let isDisableable: boolean = EnableDisableContinueBasedOnStatus(signingOrder, engagementLetterStatus, isEngagementControl);

        if (engagementLetterStatus == FormGroupStatus.None && organizerStatus == FormGroupStatus.None) {
            return (<Shimmer height={50} width={150} />);
        }
        else if (isDisableable) {
            return "";
        }
        else if (ShowClickHereToContinueTextBasedOnStatus(engagementLetterStatus, organizerStatus)) {
            return (<a className="rd-nav-link btn-finish">
                <button type="submit" onClick={this.redirectToELSign} data-test-auto='d50c43ee-bbe3-11eb-8529-0242ac130003'
                    className="btn btn-primary btn-primary-extended btn-login tablet-btn"
                    id="btnContinue2">{OrganizerConstants.SummaryPageContinueButtonText}</button>
            </a>);
        }
        else if (HideClickHereToContinueTextBasedOnStatus(organizerStatus)) {
            return "";
        }
        else if (ShowClickHereToContinueTextDependOnStatus(engagementLetterStatus, organizerStatus)) {
            return (<a className="rd-nav-link btn-finish">
                <button type="submit" onClick={this.handleContinue} data-test-auto='d50c41d2-bbe3-11eb-8529-0242ac130003'
                    className="btn btn-primary btn-primary-extended btn-login tablet-btn"
                    id="btnContinue">{OrganizerConstants.SummaryPageContinueButtonText}</button>
            </a>);
        }
        else {
            return "";
        }
    }

    private redirectToELSign = () => {
        HandleRedirect(OrganizerConstants.EngagementLetterSignPageURL + this._param.clientId);
    }

    private handleContinue = () => {
        HandleRedirect(this.getRedirectUrl());
    }

    private SummaryPageSecondaryMessage() {
        const { engagementLetterStatus, organizerStatus } = this.state;

        if (NotToShowSummaryPageSecondaryMessage(engagementLetterStatus, organizerStatus)) {
            return '';
        }
        else {
            return ShowSummaryPageSecondaryMessage();
        }
    }

    private getPreparerMessage() {
        return (
            <React.Fragment >
                <div className={"col-lg-12 col-md-12 col-sm-12 col-xs-12 container"}>
                    <div className='preparer-message-container'>
                        <div className='prepare-message-header'>
                            Message from {IsNullorEmpty(this.props.headerInfo?.companyName) ? "Tax Preparer" : this.props.headerInfo?.companyName}
                        </div>
                        <br />
                        <div className='preparer-message-content' dangerouslySetInnerHTML={{ __html: htmlencode.htmlDecode(this.props.preparerMessageStore.preparerMessage.message) }}></div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    public render() {

        const { uploadedDocumentStatus: { isCompleted } } = this.props;
        const {
            engagementLetterStatus,
            enabledSourceDocuments,
            organizerStatus,
            signingOrder,
            isEngagementControl,
            hasCustomQuestion,
            engagementSignerStatus } = this.state;
        const organizerMetadata: OrganizerMetadata = (this.props.organizerMetadata as any).organizerMetadata;

        return (<div className={"col-lg-12 col-md-12 col-sm-12 col-xs-12 organizer-welcome-page"}>
            <div className={"col-lg-10 col-md-10 col-xs-12 organizer-welcome-page-container offset-lg-1 offset-md-1"}>

                <div className={"col-lg-12 col-md-12 col-sm-12 col-xs-12 organizer-welcome-page-title-container"}>
                    <span className={"organizer-welcome-page-title"}>
                        Welcome {organizerMetadata.clientName ? organizerMetadata.clientName : < Shimmer height={30} width={200} />}
                    </span>
                </div>

                <br /><br />

                <div className={"col-lg-12 col-md-12 col-sm-12 col-xs-12 organizer-welcome-page-message-container"}>
                    {
                        engagementLetterStatus == FormGroupStatus.None
                            && organizerStatus == FormGroupStatus.None ?
                            <span /> :
                            (
                                ShowThanksMessageIfOrganizerCompletedAndDownloadable(engagementLetterStatus, organizerStatus)
                                ?
                                <span className={"organizer-welcome-page-message"}>
                                    Thank you for completing your documents!
                                    <br /><br />
                                    You may click on a completed document below to download a copy for your records
                                </span>
                                :
                                <span className={"organizer-welcome-page-message"}>
                                    {organizerMetadata.senderName ? organizerMetadata.senderName : < Shimmer height={20} width={150} />} has
                                    sent your {organizerMetadata.taxYear ? organizerMetadata.taxYear : < Shimmer height={20} width={150} />} Tax
                                    Organizer to be Completed Electronically
                                </span>)
                    }
                </div>

                <br />
                {this.props.preparerMessageStore.preparerMessage.message && this.getPreparerMessage()}
                <br />

                <div className={"col-lg-12 col-md-12 col-sm-12 col-xs-12 organizer-welcome-page-menu-container"}>

                    {this.SummaryPageSecondaryMessage()}

                    <div className={"organizer-welcome-page-menu-icon-container"}>
                        {
                            (
                                (
                                    engagementLetterStatus == FormGroupStatus.NA ||
                                    engagementLetterStatus == FormGroupStatus.None
                                ) ||
                                (signingOrder > 1 && !isEngagementControl)
                            ) ? "" :
                                (
                                    (
                                        engagementLetterStatus == FormGroupStatus.ESigned ||
                                        engagementLetterStatus == FormGroupStatus.ManuallySigned ||
                                        engagementSignerStatus == FormGroupStatus.Reviewed
                                    )
                                    ? this.getDownloadEngagementButton()
                                    : this.getSignEngagementButton())
                        }
                        { hasCustomQuestion && this.getCustomQuestionsButton() }
                        {
                            organizerStatus != FormGroupStatus.None &&
                            (
                                organizerStatus == FormGroupStatus.Completed || organizerStatus == FormGroupStatus.ManuallyCompleted
                                ? this.getOrganizerDownloadButton()
                                    : this.getOrganizerButton()
                            )
                        }

                        {
                            organizerStatus != FormGroupStatus.None &&
                            enabledSourceDocuments &&
                            (
                                isCompleted ?
                                    this.getCompletedUploadDocumentsButton() :
                                    this.getUploadDocumentsButton()
                            )
                        }
                    </div>

                    <br /><br /><br />
                    {this.getContinueButton()}
                </div>
            </div>
        </div>
        );
    }
}
