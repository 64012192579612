export class StringResource {
    public static readonly BASE_URL = "/api/";
}
 


export const DataAccessConstants = {
    BaseUrl: `${process.env.REACT_APP_API_URL}api/`
}

export const UrlConstants = {
    PreviewCoverPage: '/previewcoverpage/',
    ClientViewCoverPage: '/clientViewcoverpage/',
    CoverPage: '/coverpage/index/',
    Logout: '/account/logout/',
    SMSOTPPage: '/organizer/login/sms/',
    EmailOTPPage: '/organizer/login/email/',
    PreviewFlow: '/previewflow/',
    ClientView:'/clientView/',
    Error:'/error/',
    InvalidPage: '/InvalidLink'
}
export const PathConstants = {

    DistributeK1: "/distributeK1/",
    DistributeK1Electronically: "/distributeK1/electronic/",
    DistributeK1Manually: "/distributeK1/manual/",
    CompletionWizard: "/signcompleted/",
    Pay: "/pay/",
    ManualSign: "/manualsign/",
    OrganizerSignFlow: "/organizer/",
    PreviewFlow: "/previewflow/",
    Download: "/download/"
}

export const delayConstant = {
    signalrDownloadDelay: 20000, // 20 seconds delay for signalr download average after ziping files
};