
import { AxiosResponse } from 'axios';
import { Action, Reducer } from 'redux';
import { actionTypes } from '../ActionTypes';
import { NotificationAction, StatusType } from '../Common/NotificationStore';
import { AppThunkAction } from '../index';
import { OTPPageConstants } from '../../components/Common/Constants';
import { OTPMobilePageConstants, DisplayError } from '../../components/Common/Constants';
import { initializeAxios } from '../../core/Services/dataAccess/DataService.Axios';
import { StatusCode } from 'src/core/domain/models/Organizer/Organizer';


export interface IOTPPage {
	loading: boolean,
	error: any,
	mobileNo: string
	countryCode: string
	notificationResponse: any
}

const OTPPage: IOTPPage = {
	loading: false,
	error: null,
	mobileNo: "",
	countryCode: "",
	notificationResponse: {}

}


interface RequestOTPStateAction {
	type: actionTypes.SET_OTP_LOADING;
}

export interface OTPState {
	otpStatus: IOTPPage
}

type KnownAction =
	DispatchAction |
	NotificationAction;

type DispatchAction =
	RequestOTPStateAction



interface IClientResponse {
	isSuccess: boolean,
	errorCode: string,
	errorDescription: string,
	data: any
}


export const actionCreators = {
	pageValidity: (clientId: string, errorCallback: (errorState: any) => void): AppThunkAction<any> => (dispatch, getState) => {
		dispatch({ type: actionTypes.SET_OTP_LOADING });
		return initializeAxios().get<any>("api/OTP/" + clientId)
			.then(function (response: AxiosResponse<IClientResponse>) {

				let result = response.data;

				if (result.isSuccess) {

					dispatch({ type: actionTypes.SET_VALID_OTP_PAGE, data: result.data });
					errorCallback({});
				}
				else {
					let errorDesc = result.errorDescription;
					let errorCode = result.errorCode;

					if (errorCode && DisplayError.includes(errorCode)) {
						dispatch({
							type: actionTypes.SET_ERROR_MSG,
							data: errorDesc
						})

						errorCallback && errorCallback({ errorCode: errorCode, errorMsg: errorDesc });
					}
					else {
						dispatch({
							type: actionTypes.NOTIFICATION,
							statusMessage: errorDesc,
							statusType: StatusType.Error
						})
						dispatch({ type: actionTypes.SET_INVALID_OTP_PAGE });
						errorCallback({});
					}
				}
			})
			.catch((error: any) => {
				if (error?.response?.status === StatusCode.OrganizerUnavailable) {
					return;
				}
				dispatch({
					type: actionTypes.NOTIFICATION,
					statusMessage: OTPPageConstants.ErrorMessage.serverError,
					statusType: StatusType.Error
				})
				dispatch({ type: actionTypes.SET_OTP_ERROR, error: error.message });
			}
			);

	},
	generateMobileOTP: (clientId?: string): AppThunkAction<any> => (dispatch, getState) => {
		return initializeAxios().get<any>('/api/OTP/Mobile/Generate/' + clientId)
			.then(function (response: any) {
				let result = response.data;
				if (result) {
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: OTPMobilePageConstants.SuccessMessage.OTPGenerateSuccess,
						statusType: StatusType.Success
					})
				}
				else {
					let errorDesc = result.errorDescription;
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: errorDesc,
						statusType: StatusType.Error
					})
				}


			})
			.catch(function (error: any) {
				if (error?.response?.status === StatusCode.OrganizerUnavailable) {
					return;
				}
				dispatch({
					type: actionTypes.NOTIFICATION,
					statusMessage: OTPMobilePageConstants.ErrorMessage.serverError,
					statusType: StatusType.Error
				})
			});


	}
	, generateOTP: (clientId?: string): AppThunkAction<any> => (dispatch, getState) => {
		return initializeAxios().get<any>('/api/OTP/Generate/' + clientId)
			.then(function (response: any) {
				let result = response.data;
				if (result) {
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: OTPPageConstants.SuccessMessage.OTPGenerateSuccess,
						statusType: StatusType.Success
					})
				}
				else {

					dispatch({ type: actionTypes.OTP_GENERATE_FAILED })
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: OTPPageConstants.ErrorMessage.OTPGenerateFailed,
						statusType: StatusType.Error
					})

				}

			})
			.catch(function (error: any) {
				if (error?.response?.status === StatusCode.OrganizerUnavailable) {
					return;
				}
				dispatch({
					type: actionTypes.NOTIFICATION,
					statusMessage: OTPPageConstants.ErrorMessage.serverError,
					statusType: StatusType.Error
				})
			});
	},

	verifyOTP: (OTP: string, clientId: string, handleVerify?: (guid: string) => void, errorCallback?: (errorState: any) => void): AppThunkAction<any> => (dispatch, getState) => {
		return initializeAxios().postJson<any>('"' + OTP + '"', `/api/OTP/Validate/${clientId}`)
			.then(function (response: any) {
				let result = response.data;

				if (result.isSuccess) {
					//check the final route
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: "valid otp",
						statusType: StatusType.Success
					})
					if (handleVerify !== undefined) {
						handleVerify(result?.data?.clientId);
					}

				}
				else {
					let errorDesc = result.errorDescription;
					let errorCode = result.errorCode;

					if (errorCode && DisplayError.includes(errorCode)) {
						dispatch({
							type: actionTypes.SET_ERROR_MSG,
							data: errorDesc
						})

						dispatch({
							type: actionTypes.NOTIFICATION,
							statusMessage: errorDesc,
							statusType: StatusType.Error
						})
						errorCallback && errorCallback({ errorCode: errorCode, errorMsg: errorDesc });
					}
				}

			})
			.catch(function (error: any) {
				if (error?.response?.status === StatusCode.OrganizerUnavailable) {
					return;
				}
				dispatch({
					type: actionTypes.NOTIFICATION,
					statusMessage: OTPPageConstants.ErrorMessage.serverError,
					statusType: StatusType.Error
				})
				dispatch({ type: actionTypes.SET_OTP_ERROR, error: error.message });
			});
	},

	setMobileNo: (mobileNo: string, countryCode: string): AppThunkAction<any> => (dispatch, getState) => {
		return dispatch({ type: actionTypes.SET_MOBILE_VAL, data: { mobileNo, countryCode } })
	},
	resetNotificationResponse: (): AppThunkAction<any> => (dispatch, getState) => {
		return dispatch({ type: actionTypes.RESET_NOTIFICATION_RESPONSE })
	}
}

export const reducer: Reducer<IOTPPage> = (state: any = OTPPage, incomingAction: Action) => {
	const action = incomingAction as any;
	const currentState = Object.assign({}, state);
	switch (action.type) {

		case actionTypes.NOTIFICATION:
			const { statusMessage, statusType } = action;
			return { ...currentState, notificationResponse: { statusMessage: statusMessage, statusType: statusType } }

		case actionTypes.RESET_NOTIFICATION_RESPONSE:
			return { ...currentState, notificationResponse: {} }

		case actionTypes.SET_OTP_LOADING:
			return { ...currentState, loading: true };
		case actionTypes.SET_INVALID_OTP_PAGE:
			//set a error message stating this is a invalid page
			return { ...currentState, loading: true };
		case actionTypes.SET_VALID_OTP_PAGE:
			return { ...currentState, mobileNo: action.data };
		case actionTypes.SET_OTP_ERROR:
			//set the exception error for not getting data.
			let errorMsg = action.error;
			return { ...currentState, loading: false, error: errorMsg }
		case actionTypes.OTP_GENERATE_FAILED:
			//set the alert for OTP generate failed.
			return { ...currentState }
		case actionTypes.OTP_GENERATE_SUCCESS:
			//set the alert for OTP generate success.
			return { ...currentState }
		case actionTypes.SET_MOBILE_VAL:
			return { ...currentState, mobileNo: action.data, countryCode: action.data.countryCode };

		case actionTypes.GENERATE_TAXPAYER_OTP:
			/*add the data getting back from the api*/

			return { ...currentState };
		default:
			return currentState || OTPPage;
	}
}; 