import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { ApplicationState } from "../../store/index";
import { DefaultLayout } from './DefaultLayout';
import _ from 'lodash';
import { actionCreators as accountAction } from '../../store/Common/AccountStore';
import { actionCreators as helperAction, IUteSettings } from '../../store/Common/HelperStore';
import { actionCreators as coverAction, ErrorStatus } from '../../store/CoverPage/CoverPageStore';
import { IFormData, OrganizerFormDataViewModel, SignerDocumentModel } from '../../core/domain/models/Organizer/Organizer';
import { actionCreators as esignAction } from '../../store/Common/EsignStore';
import { actionCreators as taxDocumentAction, actionCreators as TaxDocumentStore } from '../../store/Common/TaxDocumentStore';
import { actionCreators as OrganizerStore } from '../../store/Organizer/OrganizerStore';
import { IDocument } from '../../core/domain/models/esign/Document';
import { ITaxReturn } from '../../core/domain/models/ITaxReturn';
import { ClientType } from '../../core/common/Enums';
const createLoadingSelector = (actions: string[]) => (state: ApplicationState) => {
    // returns true only when all actions is not loading
    return _(actions)
        .some((action: string) => _.get(state.loader, action, true));
};
const loadingSelector = createLoadingSelector(['HEADERINFO', 'TAXING_AUTHORITY']);

function mapStateToProps(state: ApplicationState) {
    return {
        headerInfo: state.headerInfo,
        loading: loadingSelector(state),
        taxReturn: state.taxReturn.taxDocument,
        signerData: state.esignData.signerDetails,
        downloadList: state.downloadableDocuments,
        organizerDocument: state.organizerDocument,
        organizerMetaData: state.organizerMetadata,
        currentCustomQuestionsData: state.customQuestionStore.formData,
        intialCustomQuestionsData: state.customQuestionStore.intialFormData,
        clientData: state.commonData.clientData,
        profileData: state.commonData.profileData
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {

        requestHeaderInfo: (id: string, isPreview: boolean, callback?: (data: any) => void, errorCallback?: (errorStatus: ErrorStatus) => void) =>
            dispatch(coverAction.requestHeaderInfo(id, isPreview, callback, errorCallback)),

        refreshToken: (clientId: string, failurecallback: () => void) => dispatch(accountAction.refreshToken(clientId, failurecallback)),
        requestAllTaxingAuthorities: (id: string) => dispatch(helperAction.requestAllTaxingAuthorities(id)),
        requestTaxDocument: (id: string) => dispatch(taxDocumentAction.requestTaxDocument(id)),
        requestKBAStatus: (id: string) => dispatch(taxDocumentAction.requestKBAStatus(id)),
        requestTaxDocumentclientType: (id: string) => dispatch(taxDocumentAction.requestTaxDocumentclientType(id)),
        clientAuthentication: (clientGuid: string) => dispatch(esignAction.clientAuthentication(clientGuid)),
        //esign store is not right place for mobilenumber update need to move from esign store to account store some where 
        //Vision team please do the need full just added all method to avoid webpack failure issue
        clientValidation: (clientGuid: string, data: any, successCallback: () => void, failureCallback: () => void) => dispatch(esignAction.clientValidation(clientGuid, data, successCallback, failureCallback)),
        requestSigners: (clientGuid: string) => dispatch(esignAction.requestSigners(clientGuid)),
        requestSignatureControls: (clientGuid: string) => dispatch(esignAction.requestSignatureControls(clientGuid)),
        requestPreviewSignatureControls: (clientGuid: string) => dispatch(esignAction.requestPreviewSignatureControls(clientGuid)),
        updateDOB: (clientGuid: string, id: string, dob: string, step: boolean, callback: (step: boolean) => void) => dispatch(esignAction.updateDOB(clientGuid, id, dob, step, callback)),
        updateDocumentSignatureSettingModel: (clientId: string, signatureType: number, callback: any) => dispatch(esignAction.updateDocumentSignatureSettingModel(clientId, signatureType, callback)),
        updateSignatureControlsData: (data: any) => dispatch(esignAction.updateSignatureControlsData(data)),
        updateSpouseMail: (clientGuid: string, id: string, mail: string, type: ClientType, step?: boolean, callback?: (step: boolean) => void) => dispatch(esignAction.updateSpouseMail(clientGuid, id, mail, type, step, callback)),
        sign: (clientId: string, documentData: IDocument[], callback: (status: boolean) => void) => dispatch(esignAction.sign(clientId, documentData, callback)),
        updateTaxDocument: (taxDocument: ITaxReturn) => dispatch(taxDocumentAction.updateTaxDocument(taxDocument)),
        requestNextSigner: (clientGuid: string) => dispatch(TaxDocumentStore.requestNextSigner(clientGuid)),

        requestOrganizerForms: (clientId: string) => dispatch(OrganizerStore.requestOrganizerForms(clientId)),
        requestOrganizerControls: (clientId: string) => dispatch(OrganizerStore.requestOrganizerControls(clientId)),
        submitSignedDocument: (clientId: string,
            organizerForms: OrganizerFormDataViewModel,
            successCallback?: () => void,
            failureCallback?: () => void) => dispatch(OrganizerStore.submitSignedDocument(clientId, organizerForms, successCallback, failureCallback)),
        autoSaveOrganizer: (clientId: string,
            organizerForms: OrganizerFormDataViewModel,
            organizerDocument: SignerDocumentModel,
            successCallback: (organizerDocument: SignerDocumentModel) => void,
            failureCallback?: () => void) => dispatch(OrganizerStore.autoSaveOrganizer(clientId, organizerForms, organizerDocument, successCallback, failureCallback)),
        finishDocumentSign: (clientId: string,
            organizerForms?: OrganizerFormDataViewModel,
            successCallback?: () => void,
            failureCallback?: () => void) => dispatch(OrganizerStore.finishDocumentSign(clientId, organizerForms, successCallback, failureCallback)),

        addUploadedDocument: (clientId: string,
            uploadedDocument: any,
            successCallback?: () => void,
            failureCallback?: () => void) => dispatch(OrganizerStore.addUploadedDocument(clientId, uploadedDocument, successCallback, failureCallback)),

        deleteUploadedDocument: (clientId: string,
            id: number,
            successCallback?: () => void,
            failureCallback?: () => void) => dispatch(OrganizerStore.deleteUploadedDocument(clientId, id, successCallback, failureCallback)),

        requestUploadedDocuments: (clientId: string,
            forceRefresh?: boolean) => dispatch(OrganizerStore.requestUploadedDocuments(clientId, forceRefresh)),

        completeDocumentUpload: (clientId: string,
            deletedFiles: string,
            successCallback?: () => void,
            failureCallback?: () => void) => dispatch(OrganizerStore.completeDocumentUpload(clientId, deletedFiles, successCallback, failureCallback)),

        AddNotes: (clientId: string, formId: number, notes: string, pageNo: number,
            successCallback?: () => void,
            failureCallback?: () => void,
            failureCallbackForOrganizerUnavailable?: () => void) => dispatch(OrganizerStore.AddNotes(clientId, formId, notes, pageNo, successCallback, failureCallback, failureCallbackForOrganizerUnavailable)),

        DeleteNotes: (clientId: string, FormId: number,
            successCallback?: () => void,
            failureCallback?: () => void,
            failureCallbackForOrganizerUnavailable?: () => void) => dispatch(OrganizerStore.DeleteNotes(clientId, FormId, successCallback, failureCallback, failureCallbackForOrganizerUnavailable)),

        sendForReview: (clientGuid: string,
            clientType: ClientType,
            callback: () => void,
            failureCallback?: () => void) => dispatch(OrganizerStore.sendForReview(clientGuid, clientType, callback, failureCallback)),

        reviewOrganizer: (clientGuid: string,
            callback?: () => void) => dispatch(OrganizerStore.reviewOrganizer(clientGuid, callback)),

        ignoreReviewOrganizer: (clientGuid: string,
            callback?: () => void) => dispatch(OrganizerStore.ignoreReviewOrganizer(clientGuid, callback)),

        setIsCoverPage: (isCoverPage: boolean) => dispatch(helperAction.setIsCoverPage(isCoverPage)),
        getWalkMeScript: (id: string, callback: (script: string) => void) => dispatch(helperAction.getWalkMeScript(id, callback)),

        dispatch,

        autoSaveCustomQuestions: (clientId: string,
            formData: IFormData,
            successCallback: (formData: IFormData) => void,
            failureCallback?: () => void) => dispatch(OrganizerStore.autoSaveCustomQuestions(clientId, formData, successCallback, failureCallback)),
        getHeaderData: (clientId: string) => dispatch(helperAction.getHeaderData(clientId)),
        getClientInfo: (clientId: string) => dispatch(helperAction.getClientInfo(clientId)),
        checkIfUserExistsInOneHub: (clientId: string, callback: (isUserExists: boolean) => void) => dispatch(helperAction.checkIfUserExistsInOneHub(clientId, callback)),
        getOneHubHomeRedirectUrl: (clientId: string, callback: (url: string) => void) => dispatch(helperAction.getOneHubHomeRedirectUrl(clientId, callback)),
        getOneHubRedirectUrl: (clientId: string, callback: (url: string) => void) => dispatch(helperAction.getOneHubRedirectUrl(clientId, callback)),
        getOnehubRedirectURLForClientView: (clientId: string, callback: (url: string) => void) => dispatch(helperAction.getOnehubRedirectURLForClientView(clientId, callback)),
        getUTESettings: (clientId: string, callback?: (data: IUteSettings) => void) => dispatch(helperAction.getUTESettings(clientId, callback)),
        logout: (clientGuid: string, callback: (id: string) => void) =>
            dispatch(accountAction.logout(clientGuid, callback)),

    }
}
const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(DefaultLayout));

