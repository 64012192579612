import PdfViewer from 'awesome-pdf-viewer';
import { CheckBoxControl } from 'awesome-pdf-viewer/dist/Controls/CheckBox/CheckBoxControl';
import ControlBase, {
    CheckBoxData, DateData, DropdownData, LabelData, RadioButton,
    RadioButtonData, SignatureData, TextBoxData, TextBoxTypes
} from 'awesome-pdf-viewer/dist/Controls/ControlBase';
import DatePickerControl from 'awesome-pdf-viewer/dist/Controls/DatePickerControl/DatePickerControl';
import { DropdownControl } from 'awesome-pdf-viewer/dist/Controls/DropdownControl/DropdownControl';
import { LabelControl } from 'awesome-pdf-viewer/dist/Controls/LabelControl';
import { NumericTextboxControl } from 'awesome-pdf-viewer/dist/Controls/NumericTextboxControl';
import { RadioButtonControl } from 'awesome-pdf-viewer/dist/Controls/RadioButtonControl/RadioButtonControl';
import { SignatureControlProps } from 'awesome-pdf-viewer/dist/Controls/SignatureControl';
import { TextBoxControl } from 'awesome-pdf-viewer/dist/Controls/TextBoxControl';
import ControlLayer from 'awesome-pdf-viewer/dist/Layers/ControlLayer';
import Header from 'awesome-pdf-viewer/dist/layout/Header';
import { PageSize } from 'awesome-pdf-viewer/dist/layout/LayoutBase';
import LeftPanel from 'awesome-pdf-viewer/dist/layout/LeftPanel';
import Main from 'awesome-pdf-viewer/dist/layout/Main';
import { RightPanel } from 'awesome-pdf-viewer/dist/layout/RightPanel';
import ViewPanel from 'awesome-pdf-viewer/dist/layout/ViewPanel';
import CustomOptions from 'awesome-pdf-viewer/dist/toolbar/CustomOptions';
import Pagination from 'awesome-pdf-viewer/dist/toolbar/Pagination';
import Toolbar from 'awesome-pdf-viewer/dist/toolbar/Toolbar';
import Zoom from 'awesome-pdf-viewer/dist/toolbar/Zoom';
import { PageMode, PdfSource } from 'awesome-pdf-viewer/dist/viewer/ViewerBase';
import moment from 'moment';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { isUndefined } from 'util';
import { ClientType } from '../../../core/common/Enums';
import { ControlValue } from '../../../core/domain/models/Organizer/Controls';
import {
    BlobForm, Form, FormType, OrganizerForm, OrganizerFormData, SignerDocumentModel, UploadedDocument
} from '../../../core/domain/models/Organizer/Organizer';
import { container } from '../../../startup/inversify.config';
import { TYPES } from '../../../startup/types';
import * as OrganizerStore from '../../../store/Organizer/OrganizerStore';
import { OrganizerConstants, OrganizerStatusChangedWarning, PreviewConstants, SourceDocumentConstants } from '../../Common/Constants';
import { MarsNotifier } from '../../Common/Notification/MarsNotifier';
import { GetDataFromStorage, StringtoBoolean } from '../../Helper/HelperFunction';
import { UploadModal } from '../../UploadDocuments/UploadModal';
import { Footer } from '../Footer/Footer';
import { FormList } from '../FormList/FormList';
import { TaxPayerNotes } from '../Notes/Notes';
import { SourceDocumentBox } from '../SourceDocument/SourceDocument';
import { ViewerHelper } from './ViewerHelper';
import { ILoader } from '../../../core/utilities/ui/Loader';
import { IDateUtilities } from '../../../core/utilities/date/DateUtilities';
import { IDialogBox } from '../../../core/utilities/ui/DialogBox';

export type ViewProps = {
    forms: SignerDocumentModel,
    blobForms: BlobForm[],
    onToggleRedirectWarning(value: boolean): void,
    isReadMode: boolean,
    page: number;
    handlePageChange: (page: number, callback: () => void) => void;
    showValidationError: boolean;
    scrollToControl: string;
    clientId: string;
    uploadedDocuments: UploadedDocument[],
    clientType: ClientType;
    onSave(): void;
    onFinish(): void;
    onGoBack(): void;
    currentPage: number;
    changeStatusToCompleted: () => void;
    isSourceDocumentUploadCompleted: boolean;
    requestUploadedDocumentStatus: (clientId: string) => void;
    setUploadedDocumentError: () => void;
    deleteUploadedDocument(clientId: string, id: number, successCallback?: () => void): void;
    requestUploadedDocuments(clientId: string): void;
    getSourceDocumentMetadataAsync?(clientId: string, uploadedDocumentId: number, successCallback?: () => void): void;
}
    & typeof OrganizerStore.actionCreators
    & RouteComponentProps<{}>

export interface ViewState {
    hideStartNavigationControl: boolean;
    signatureData: SignatureData | undefined;
    scale: number;
    tpNotes: string;
    formId: number;
    isPaginationDisabled: boolean;
    showSourceDocumentPopup: boolean;
    currentFormName: string;
    isCurrentFormSet: boolean;
    finishEnabled: boolean;
    taxPayerNote: string;
}

const DEFAULT_VIEWER_SCALE: number = 1;
const DEFAULT_VIEWER_SCALE_2: number = 1.0001;
const PDF_VIEWER_BASE_HEIGHT = 792;
const PDF_VIEWER_BASE_WIDTH = 612;

const loader = container.get<ILoader>(TYPES.ILoader);
const dateUtilities = container.get<IDateUtilities>(TYPES.IDateUtilities);
const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);

export class Viewer extends React.Component<ViewProps, ViewState> {

    private _viewPanel: any;
    private _controlLayer: any;
    public _controlList: any[] = [];
    private _toolbar: any;
    private _controlDisplayPanel: any;
    private _bookmarkPanel: any;
    private _formId: number = 0;
    constructor(props: any) {
        super(props);
        this.state = {
            hideStartNavigationControl: false,
            signatureData: undefined,
            scale: DEFAULT_VIEWER_SCALE,
            tpNotes: "",
            formId: 0,
            isPaginationDisabled: false,
            showSourceDocumentPopup: false,
            currentFormName: "",
            isCurrentFormSet: false,
            finishEnabled: false,
            taxPayerNote: ""
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps: ViewProps) {

        if (nextProps.forms != undefined) {
            const { forms, page } = nextProps;

            let tmpForm: Form | undefined = (forms as any).data.formGroup[0].forms.find((x: any) => x.individualFilePageNo == page);

            if (tmpForm) {
                this._formId = tmpForm.formId;
                this.setState({
                    formId: tmpForm.formId,
                    taxPayerNote: tmpForm.message ? tmpForm.message : ""
                })
            }
        }
    }

    componentDidMount() {
        this.setReferences();
    }

    setReferences() {
        this._toolbar && this._toolbar.setViewerReference(this._viewPanel);
        this._viewPanel && this._viewPanel.setToolbarReference(this._toolbar);
        this._viewPanel && this._viewPanel.setControlsReference(this._controlList);
        this._viewPanel && this._viewPanel.setControlLayerReference(this._controlLayer);
        this._controlLayer && this._controlLayer.setViewerReference(this._viewPanel);
        this._controlLayer && this._controlLayer.setControlsReference(this._controlList);
        this._controlLayer && this._controlLayer.setcontrolDisplayPanelReference(this._controlDisplayPanel);
        this._controlDisplayPanel && this._controlDisplayPanel.setControlsReference(this._controlList);
        this._controlDisplayPanel && this._controlDisplayPanel.setViewerReference(this._viewPanel);
        this._viewPanel.setBookmarkPanelReference(this._bookmarkPanel);
    }

    componentDidUpdate(prevProps: ViewProps) {
        this.setReferences();
        if (this.props.showValidationError) {
            if (prevProps.page != this.props.page) {
                this.handleGotoPage(this.props.page, true);
            }
            this._controlLayer._controlLayerManager.setControlFocus(this.props.scrollToControl);
        }

        if (this.props.currentPage != prevProps.currentPage) {
            this.setState({ currentFormName: this.props.forms?.data?.formGroup[0]?.forms[this.props.currentPage - 1]?.formName })
        } else if (this.state.currentFormName !== "" && this.props.forms?.data?.formGroup[0]?.forms?.length > 0 && !this.state.isCurrentFormSet) {
            this.setState({ currentFormName: this.props.forms?.data?.formGroup[0]?.forms[this.props.currentPage - 1]?.formName })
            this.setState({ isCurrentFormSet: true })
        }
    }

    private createControls = () => {

        const _self = this;
        try {
            const { forms, blobForms, page } = this.props;
            let controlCollection: any[] = [];
            this._controlList = [];

            let tmpForm: Form | undefined = (forms as any).data.formGroup[0].forms?.find((x: any) => x.individualFilePageNo == page);

            if (tmpForm) {
                let pageNo = tmpForm.individualFilePageNo ? tmpForm.individualFilePageNo : tmpForm.pageNo;

                if (tmpForm.formType == FormType.Organizer) {

                    const formData: OrganizerFormData = tmpForm.formData as OrganizerFormData;

                    if (formData) {// formData will be null incase of moving pages - bug - #46955 
                        const blobForm: BlobForm | undefined = blobForms?.find(x => x.formGuid == (tmpForm ? tmpForm.formGuid : "")); // fetch corresponding control list from blob form data
                        if (blobForm && blobForm.controlList) {
                            !_self.props.isReadMode && blobForm.controlList.sort(ViewerHelper.compareFormControl);

                            const viewport: PageSize = _self._viewPanel.getPageSize(pageNo, this.state.scale);
                            blobForm.controlList.forEach(function (control: any) {
                                control.readonly = (_self.props.isReadMode ? true : control.readonly);
                                if (viewport && !(viewport.width > viewport.height) && control.boundingrectangle) {
                                    if (control.boundingrectangle.left > viewport.width || control.boundingrectangle.top > viewport.height) {
                                        return;
                                    } // quick fix for bug #47331
                                }
                                let tmpControl: ControlValue | any = formData.controlValues?.find(x => x.controlGuid == control.id);
                                if (!tmpControl) {
                                    tmpControl = control.controlData;
                                }

                                let pdfViewerTop: number | undefined;
                                let pdfViewerLeft: number | undefined;
                                const rotationDegree = (tmpForm as any)?.rotationDegree;

                                pdfViewerLeft = ControlBase.getPdfViewerControlLeftPosition(control.boundingRectangle.left);
                                pdfViewerTop = (viewport.width > viewport.height || rotationDegree === 90) ? ControlBase.getPdfViewerControlTopPosition(viewport.width, control.boundingRectangle.top) :
                                    ControlBase.getPdfViewerControlTopPosition(viewport.height, control.boundingRectangle.top);

                                switch (control.controlType) {

                                    case 1: // TextBox
                                    case 3: // TextBox

                                        let type: TextBoxTypes = TextBoxTypes.Textbox;
                                        let textboxData: TextBoxData = TextBoxData.createNullObject();

                                        if (control.controlType == 3) { // Date
                                            controlCollection.push(
                                                <DatePickerControl
                                                    isRequired={false}
                                                    ref={(ref) => _self._controlList.push(ref)}
                                                    helptext={control.helptext}
                                                    key={ViewerHelper.ControlKeyPrefix + control.id}
                                                    page={pageNo}
                                                    id={ViewerHelper.ControlIdPrefix + control.id}
                                                    name={"Date Picker"}
                                                    width={control.boundingRectangle.width}
                                                    height={control.boundingRectangle.height}
                                                    top={pdfViewerTop}
                                                    left={pdfViewerLeft}
                                                    placement={control.textPlacement}
                                                    dateData={tmpControl.value === null || tmpControl.value === "Invalid date" ? DateData.createNullObject() : DateData.create(new Date(moment(tmpControl.value).add(0, 'd').format('MM/DD/YYYY')))}
                                                    dateFormat="MM/dd/yyyy"
                                                    onChange={_self.updateOrganizerControl}
                                                    cssClassName={ViewerHelper.getDatePickerClassName(tmpControl.filledBy)}
                                                    disabled={_self.props.isReadMode}
                                                    highlightIfChanged={true}
                                                />);
                                            // }
                                        }
                                        else {

                                            if (control.dataType == 0 || control.dataType == 1) { // textbox & SSN
                                                type = TextBoxTypes.Textbox;
                                                textboxData = TextBoxData.create(tmpControl.value);
                                            }
                                            else if (control.dataType == 4) { // textarea
                                                type = TextBoxTypes.TextArea;
                                                textboxData = TextBoxData.create(tmpControl.value);
                                            }
                                            else if (control.dataType == 2 || control.dataType == 6) { // Number & Amount

                                                type = TextBoxTypes.Number;

                                                    textboxData = TextBoxData.create(tmpControl.value);

                                                controlCollection.push(<NumericTextboxControl
                                                    id={ViewerHelper.ControlIdPrefix + control.id}
                                                    key={ViewerHelper.ControlKeyPrefix + control.id}
                                                    ref={(ref: any) => {
                                                        _self._controlList.push(ref)
                                                    }}
                                                    page={pageNo}
                                                    textboxData={textboxData}

                                                    width={control.boundingRectangle.width}
                                                    height={control.boundingRectangle.height}
                                                    placement={control.textPlacement}
                                                    maxLength={control.maxLength}
                                                    top={pdfViewerTop}
                                                    left={pdfViewerLeft}
                                                    name={"Textbox"}
                                                    placeholder={control.placeholder}
                                                    helptext={control.helptext}
                                                    isRequired={false}
                                                    disabled={control.readonly} type={type}
                                                    data={control.customData}
                                                    onChange={_self.updateOrganizerControl} highlightIfChanged={true}
                                                    color={ViewerHelper.getControlColor(tmpControl.filledBy)} />);

                                                break;
                                            }

                                            else if (control.dataType == 5) { // Label

                                                controlCollection.push(<LabelControl
                                                    id={ViewerHelper.ControlIdPrefix + control.id}
                                                    key={ViewerHelper.ControlKeyPrefix + control.id}
                                                    ref={(ref: any) => {
                                                        _self._controlList.push(ref)
                                                    }}
                                                    page={pageNo}
                                                    value={LabelData.create(control.controlData.value)}
                                                    width={control.boundingRectangle.width}
                                                    height={control.boundingRectangle.height}
                                                    placement={control.textPlacement}
                                                    top={pdfViewerTop}
                                                    left={pdfViewerLeft}
                                                    name={"Label"}
                                                    data={control.customData} />);

                                                break;
                                            }
                                        }

                                        controlCollection.push(<TextBoxControl id={ViewerHelper.ControlIdPrefix + control.id}
                                            key={ViewerHelper.ControlKeyPrefix + control.id}
                                            ref={(ref: any) => {
                                                _self._controlList.push(ref)
                                            }}
                                            page={pageNo}
                                            textboxData={textboxData}

                                            width={control.boundingRectangle.width}
                                            height={control.boundingRectangle.height}
                                            placement={control.textPlacement}
                                            maxLength={control.maxLength}
                                            top={pdfViewerTop}
                                            left={pdfViewerLeft}
                                            name={"Textbox"}
                                            placeholder={control.placeholder}
                                            helptext={control.helptext}
                                            isRequired={false}
                                            disabled={control.readonly} type={type}
                                            data={control.customData}
                                            onChange={_self.updateOrganizerControl} highlightIfChanged={true}
                                            color={ViewerHelper.getControlColor(tmpControl.filledBy)} />);

                                        break;

                                    case 2:// checkbox

                                        controlCollection.push(<CheckBoxControl id={ViewerHelper.ControlIdPrefix + control.id}
                                            key={ViewerHelper.ControlKeyPrefix + control.id}
                                            ref={(ref: any) => {
                                                _self._controlList.push(ref)
                                            }}
                                            page={pageNo}
                                            checkBoxData={CheckBoxData.create((tmpControl.value == true || tmpControl.value == "True") ? true : false)}
                                            width={15}
                                            height={15}
                                            top={pdfViewerTop}
                                            left={pdfViewerLeft}
                                            name={"Checkbox"}
                                            helptext={control.helptext}
                                            isRequired={control.required}
                                            disabled={control.readonly} data={control.customData}
                                            onChange={_self.updateOrganizerControl}
                                            cssClassName={ViewerHelper.getCheckBoxClassName(tmpControl.filledBy)} />);

                                        break;

                                    case 4: // Dropdown

                                        controlCollection.push(<DropdownControl
                                            id={ViewerHelper.ControlIdPrefix + control.id}
                                            key={ViewerHelper.ControlKeyPrefix + control.id}
                                            ref={(ref: any) => {
                                                _self._controlList.push(ref)
                                            }}
                                            page={pageNo}
                                            value={DropdownData.create(tmpControl.value || control.defaultItem)}
                                            items={control.options}
                                            width={control.boundingRectangle.width}
                                            height={control.boundingRectangle.height}
                                            placement={control.textPlacement}
                                            top={pdfViewerTop}
                                            left={pdfViewerLeft}
                                            name={"List"}
                                            placeholder={control.placeholder}
                                            helptext={control.helptext}
                                            isRequired={control.required}
                                            disabled={control.readonly}
                                            data={control.customData}
                                            onChange={_self.updateOrganizerControl} color={ViewerHelper.getControlColor(tmpControl.filledBy)} />);

                                        break;

                                    case 6: // Signature

                                        //let signatureData: SignatureData | undefined = undefined;

                                        //if ((tmpControl.controlData as any).signatureMode != 0) {
                                        //    signatureData = SignatureData.create(
                                        //        (tmpControl.controlData as any).name,
                                        //        (tmpControl.controlData as any).uri, (tmpControl.controlData as any).signatureMode);
                                        //}

                                        //controlCollection.push(<SignatureControl
                                        //    id={ViewerHelper.ControlIdPrefix + control.id}
                                        //    key={ViewerHelper.ControlKeyPrefix + control.id}
                                        //    ref={(ref: any) => {
                                        //        _self._controlList.push(ref)
                                        //    }}
                                        //    data={{ controlType: 1, controlId: control.id }}
                                        //    page={pageNo}
                                        //    width={100}
                                        //    height={20}
                                        //    top={control.boundingRectangle.top}
                                        //    left={control.boundingRectangle.left}
                                        //    name={"Signature"}
                                        //    helptext={control.helptext}
                                        //    isRequired={control.required}
                                        //    disabled={true}
                                        //    onAddSignature={_self.setAllSignatureControlValueToSame}
                                        //    onClick={_self.setSignature}
                                        //    signatureData={signatureData} />);

                                        break;


                                    case 8: // RadioButtonGroup


                                        let radioButtons: RadioButton[] = [];

                                        control.controlData.items.forEach(function (radio: any) {

                                            let selected: boolean = false;

                                            if (radio.id == tmpControl.value) {
                                                selected = true;
                                            }

                                            const tmpRadioButtonData = RadioButtonData.create(selected, "");
                                            const tmpRadioButton: RadioButton = RadioButton.create(radio.name,
                                                radio.boundingRectangle.top,
                                                radio.boundingRectangle.left,
                                                radio.boundingRectangle.width,
                                                radio.boundingRectangle.height,
                                                tmpRadioButtonData);

                                            tmpRadioButton.id = radio.id;

                                            radioButtons.push(tmpRadioButton);

                                        });

                                        controlCollection.push(<RadioButtonControl
                                            id={ViewerHelper.ControlIdPrefix + control.id}
                                            key={ViewerHelper.ControlKeyPrefix + control.id}
                                            ref={(ref) => { _self._controlList.push(ref) }}
                                            page={pageNo}
                                            isRequired={control.required}
                                            disabled={control.readonly}
                                            width={control.boundingRectangle.width}
                                            height={control.boundingRectangle.height}
                                            top={pdfViewerTop}
                                            left={pdfViewerLeft}
                                            helptext={control.helptext}
                                            name={"Options"}
                                            items={radioButtons}
                                            onChange={_self.updateOrganizerControl} color={ViewerHelper.getControlColor(tmpControl.filledBy)} />);

                                        break;

                                    default:
                                        throw new Error("Selected control not implemented !!");
                                        break;
                                }
                            });
                        }
                    }
                }
            }
            return controlCollection;
        }
        catch (error) {
            if (_self.props.isReadMode) {
                alert("Taxpayer preview for this organizer is no longer available. Please try again.");
            }
            throw error;
        }
    }

    private setAllSignatureControlValueToSame = (signatureData: SignatureData, controlProps: SignatureControlProps) => {

        const { forms } = this.props;
        const _self = this;

        this.setState({ signatureData: signatureData }, () => {

            this._controlList.forEach(function (item) {

                if (item && item.props.data && item.props.data.controlType == 1) { // signature control

                    if (item.props.id == controlProps.id || item.isSigned()) {

                        item.setControlData({
                            name: signatureData.name,
                            signature: signatureData.signature,
                            signatureMode: signatureData.signatureMode
                        });

                        _self.updateOrganizerControl(signatureData, item.props);
                    }
                    else {
                        item.setControlData(item.getControlData());
                    }
                }

            });

            ViewerHelper.setSignatureData(forms, { name: signatureData.name, uri: signatureData.signature, signatureMode: signatureData.signatureMode });
        });
    }

    setSignature = (controlProps: any) => {

        const { forms } = this.props;

        const signatureData: any = ViewerHelper.getSignatureData(forms);

        const control = this._controlList.find(x => x && x.props.id == controlProps.id);

        if (signatureData) {
            control && control.setControlData({ name: signatureData.name, signature: signatureData.uri, signatureMode: signatureData.signatureMode });
        }
        else {
            control && control.openSignatureModal();
        }

        ViewerHelper.setSignatureData(forms, (signatureData as any), controlProps.data.controlId);
    }

    public updateOrganizerControl = (controlData: any, controlPops: any) => {
        if (controlPops?.name === "Date Picker" && moment(controlData.value).year() > 9999) {
            return;
        }
        const { clientType } = this.props
        const currentForm: Form | undefined = this.getCurrentForm();
        if (currentForm) {

            if (currentForm.formType == FormType.Organizer) {
                const currentBlobForm: BlobForm | undefined = this.getCurrentBlobForm(currentForm.formGuid);
                currentBlobForm && ViewerHelper.updateOrganizerFormControl(currentBlobForm, currentForm, controlData, controlPops, clientType);
            }
        }
    }

    handleGotoPage = (page: number, forceRefresh?: boolean, form?: any) => {

        if (forceRefresh || this.props.page != page) {
            this._toolbar && this._toolbar.handleZoomChange(DEFAULT_VIEWER_SCALE);
            this._viewPanel && this._viewPanel.gotoPage(page);
        } else {
            loader.hide();
        }

        this.setState({ taxPayerNote: form && form.message ? form.message : "" });
    }

    handleBookMarkFilter = (isPaginationdisable: boolean) => {
        this.setState({
            isPaginationDisabled: isPaginationdisable
        })
    }

    private handlePageChange = (pageProperties: any) => {

        const _self = this;
        this._toolbar && this._toolbar.handleZoomChange(DEFAULT_VIEWER_SCALE);
        // settimeout for loading controls in page dont remove

        //if (loader.loadingStatus == false) {
        //    loader.show();
        //}

        setTimeout(function () {
            const { forms } = _self.props;
            let tmpForm: Form | undefined = (forms as any).data.formGroup[0].forms.find((x: any) => x.individualFilePageNo == pageProperties.page);
            _self.props.handlePageChange(pageProperties.page, loader.hide);
            if (tmpForm) {
                _self._formId = tmpForm.formId;
                _self.setState({
                    formId: tmpForm.formId
                }, () => {
                    loader.hide();
                });
                _self.GetFormNotes();
            }
        }, 100);
    }

    private getCurrentForm = (): OrganizerForm | undefined => {

        const { forms, page } = this.props;

        const currentForm: OrganizerForm | undefined = (forms as any).data.formGroup[0].forms.find((x: any) => x.individualFilePageNo == page);
        return currentForm;
    }

    private getCurrentBlobForm = (formGuid: string): BlobForm | undefined => {

        const { blobForms } = this.props;
        const currentForm: BlobForm | undefined = blobForms.find((x: any) => x.formGuid == formGuid);
        return currentForm;
    }

    public onDeleteNotes = () => {
        const _self = this;
        const { forms, page } = this.props;

        let tmpForm: Form | undefined = (forms as any).data.formGroup[0].forms.find((x: any) => x.individualFilePageNo == page);
        if (tmpForm) {
            _self.setState({ formId: tmpForm.formId, taxPayerNote: "" })
            this.props.DeleteNotes(
                _self.props.clientId,
                tmpForm.formId,
                undefined,
                this.saveError,
                () => { this.props.onToggleRedirectWarning(false) }
            )
            tmpForm.message = "";
        }
    }

    public onAddNotes = (Notes: string) => {
        const _self = this;
        const { forms, page } = this.props;

        let tmpForm: Form | undefined = (forms as any).data.formGroup[0].forms.find((x: any) => x.individualFilePageNo == page);
        if (tmpForm) {
            _self.setState({ formId: tmpForm.formId, taxPayerNote: Notes })
            this.props.AddNotes(
                _self.props.clientId,
                tmpForm.formId,
                Notes,
                isUndefined(tmpForm.individualFilePageNo) ? 0 : tmpForm.individualFilePageNo,
                undefined,
                this.saveError,
                () => { this.props.onToggleRedirectWarning(false) }
            );
            tmpForm.message = Notes;
        }
    }

    public GetFormNotes = () => {
        const _self = this;
        const { forms, page } = this.props;

        let tmpForm: Form | undefined = (forms as any).data.formGroup[0].forms.find((x: any) => x.individualFilePageNo == page);
        if (tmpForm) {
            _self.setState({ formId: tmpForm.formId, taxPayerNote: tmpForm.message ? tmpForm.message : "" })
        }
    }

    public ontaxPayerNotesChange(event: any) {
        this.setState({ tpNotes: event.target.value })
    }

    public OpenSourceDocumentPopup = () => {
        if (this.props.isReadMode) {
            dialogBox.alert(SourceDocumentConstants.UploadNotAllowedMessage);
        }
        else {
            this.setState({ showSourceDocumentPopup: true })
        }
    }

    saveError = () => {
        window.onbeforeunload = null;
        dialogBox.refreshAlert(OrganizerStatusChangedWarning, () => {
            this.handleRedirect(OrganizerConstants.OrganizerSummaryPageURL + this.props.clientId);
        });
    }

    private handleRedirect = (url: string) => {
        window.location.href = url;
    }

    public CloseSourceDocumentPopup = () => {
        this.setState({ showSourceDocumentPopup: false })
    }

    private OnDownloadFile = (Url: string, fileName: string, isReadMode: boolean) => {
        if (isReadMode) { MarsNotifier.Warning(PreviewConstants.DownloadMessage, null); }
        else {
            var a = document.createElement("a");
            a.href = Url;
            a.setAttribute("download", fileName);
            a.click();
        }
    }

    onDocumentLoad = () => {
        loader.hide();
        this.setState({ finishEnabled: true })
    }

    public render() {

        const { forms, onToggleRedirectWarning, isReadMode,
            onSave, onFinish, onGoBack, currentPage,
            isSourceDocumentUploadCompleted,
            deleteUploadedDocument,
            requestUploadedDocuments,
            getSourceDocumentMetadataAsync
        } = this.props;
        const currentForm: OrganizerForm | undefined = this.getCurrentForm();

        const enabledSourceDocument: boolean = StringtoBoolean(GetDataFromStorage(SourceDocumentConstants.IsSourceDocumentEnabled));

        const documentUrl: string = (forms as any).data.documentUrl;
        const messge: string = ((forms as any).data).formGroup[0]?.forms?.find((i: { formId: number; }) => i.formId == this._formId)?.message == undefined ? "" : ((forms as any).data).formGroup?.forms?.find((i: { formId: number; }) => i.formId == this._formId)?.message!;
        var pdfSource = PdfSource.createFromUrl(documentUrl);
        const rightPanelStyles: any = enabledSourceDocument && currentForm?.enabledSourceDocument ?
            { flexDirection: 'column', justifyContent: 'space-between' } :
            { flexDirection: 'column-reverse', justifyContent: 'flex-start' };
        return (<React.Fragment>
            <PdfViewer id={"awesome-pdf-viewer-organizer"} readMode={isReadMode}>
                <Header>
                    <Toolbar ref={(ref: any) => this._toolbar = ref}
                        hideRightPanel={false}
                        hideReadOnly={true}
                        showDownload={true}
                        onBeforeDownload={() => { onToggleRedirectWarning(false) }}
                        onAfterDownload={() => { onToggleRedirectWarning(true) }}
                        downloadFileName={OrganizerConstants.DownloadOrganizerFileName}
                        onDownload={() => { this.OnDownloadFile(documentUrl, OrganizerConstants.DownloadOrganizerFileName, isReadMode) }}   >
                        <Pagination
                            disablePagination={this.state.isPaginationDisabled}
                        />
                        <Zoom />
                        <CustomOptions />
                    </Toolbar>
                </Header>

                <Main>

                    <LeftPanel>
                        <FormList forms={(forms as any).data.formGroup[0].forms} onClickForm={this.handleGotoPage}
                            onFilterUpdate={this.handleBookMarkFilter} sourceDocumentSettingEnabled={enabledSourceDocument} />
                    </LeftPanel>


                    <ControlLayer ref={(ref: any) => this._controlLayer = ref}
                        useDefaultNavigationStartControl={false} >

                        {this.createControls()}
                    </ControlLayer>

                    <ViewPanel ref={(ref: any) => this._viewPanel = ref} pageMode={PageMode.Classic}
                        pdfWorkerSource = {`${process.env.PUBLIC_URL}/pdf.worker.min.mjs`}
                        onScaleChanged={(scale: number) => { this.setState({ scale: scale }) }}
                        onPageChanged={this.handlePageChange}
                        onDocumentLoad={this.onDocumentLoad}
                        pdfSource={pdfSource}
                        url={documentUrl} defaultPage={this.props.page}>
                    </ViewPanel>

                    <RightPanel>
                        <div className="right-view-panel-container" style={rightPanelStyles}>
                            <div className={"view-OrgSourceDocument"}>
                                {enabledSourceDocument && currentForm?.enabledSourceDocument &&
                                    <SourceDocumentBox openSourceDocumentPopup={this.OpenSourceDocumentPopup}
                                        currentFormName={this.state.currentFormName} currentFormId={this.state.formId} organizerDocument={forms}
                                        isSourceDocumentUploadCompleted={isSourceDocumentUploadCompleted}
                                        deleteUploadedDocument={deleteUploadedDocument}
                                        getSourceDocumentMetadataAsync={getSourceDocumentMetadataAsync}
                                        requestUploadedDocuments={requestUploadedDocuments}
                                        clientId={this.props.clientId}
                                        disabled={isReadMode}
                                    />
                                }
                            </div>
                            {this._formId > 0 &&
                                <TaxPayerNotes
                                    taxPayerNotes={this.state.taxPayerNote}
                                    ontaxPayerNotesChange={this.ontaxPayerNotesChange}
                                    onDelete={this.onDeleteNotes}
                                    onAdd={this.onAddNotes}
                                    id={this.state.formId}
                                    {...this.props}
                                />
                            }
                        </div>
                    </RightPanel>
                </Main>
            </PdfViewer>

            <UploadModal
                show={this.state.showSourceDocumentPopup}
                formName={currentForm == undefined ? '' : currentForm.bookmark}
                formId={currentForm?.formId}
                onClose={this.CloseSourceDocumentPopup}
                disabled={isReadMode || isSourceDocumentUploadCompleted}
                {...this.props}
            />

            <Footer
                onSave={onSave} finishEnabled={this.state.finishEnabled}
                onFinish={onFinish} onGoBack={onGoBack} currentPage={currentPage} viewPanel={this._viewPanel} />
        </React.Fragment>);
    }
}