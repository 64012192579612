import { History } from 'history';
import { cloneDeep, isEqual } from 'lodash';
import * as React from 'react';
import IdleTimer from 'react-idle-timer';
import { match, RouteComponentProps } from 'react-router';
import { MarsNotifier, Toaster } from '../../components/Common/Notification/MarsNotifier';
import { UrlConstants } from '../../core/common/Constants';
import { IOrganizerService } from '../../core/Services/OrganizerService/OrganizerService';
import { ILocalStore } from '../../core/utilities/LocalStore';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import { OverlayLoader } from '../Common/Loader/OverlayLoader';
import Notification from '../Common/Notification/NotificationContainer';
import { Header } from './Header';
import { SessionTimeout } from '../Account/SessionTimeout';
import * as AccountStore from '../../store/Common/AccountStore';
import * as HelperStore from '../../store/Common/HelperStore';
import * as OrganizerStore from '../../store/Organizer/OrganizerStore';
import { IHeaderInfoViewModel } from '../../core/domain/viewModels/IHeaderInfoViewModel';
import { OrganizerFormDataViewModel, SignerDocumentModel } from '../../core/domain/models/Organizer/Organizer';
import { IFormData, intialFormData } from '../../core/domain/models/Organizer/CustomQuestionModel';
import { GetDataFromStorage, IsClientViewFromCache, IsPreviewModeFromCache } from '../Helper/HelperFunction';
import { AutoSave, Common, SignProcessInfoConstants } from '../Common/Constants';
import { logger } from '../../routes';
import { SignProcessInfo } from '../../core/domain/models/signingprocess/SignProcess';
import { SignalRWebSocket } from '../SignalR/SignalRWebSocket';
declare global {
    interface Window {
        Variables: any;
        pendo: any
    }
}

export type DefaultLayoutProps =
    {
        updateSignalROrganizerStatus: (status: any) => void;
        headerInfo: IHeaderInfoViewModel,
        match: match;
        history: History;
        loading: boolean;
        organizerDocument: SignerDocumentModel;
        organizerMetaData: any;
        currentCustomQuestionsData: IFormData;
        intialCustomQuestionsData: IFormData;
        clientData: any;
        profileData: any;
    }
    & typeof AccountStore.actionCreators
    & typeof HelperStore.actionCreators
    & typeof OrganizerStore.actionCreators
    & RouteComponentProps<{}>;


const localStore = container.get<ILocalStore>(TYPES.ILocalStore);
const organizerService = container.get<IOrganizerService>(TYPES.IOrganizerService);

const SessionIdleMinutes = Number(process.env.REACT_APP_SESSION_IDLE_MINUTES);
const AutoSaveInterval = Number(process.env.REACT_APP_AUTO_SAVE_INTERVAL_MS);
const RefreshTokenInterval = Number(process.env.REACT_APP_REFRESH_TOKEN_INTERVAL_MS);

const WarningVisibleMinutes = 1;

interface IDefaultLayoutState {
    showTimeout: boolean;
    organizerData: SignerDocumentModel;
    customQuestionsData: IFormData;
    encodedClientId: string;
    uteSettings: HelperStore.IUteSettings;
};
interface IPendoPayload {
    name: string;
    email: string;
    companyId: string;
    companyName: string;
    userId: string;
    isTestCompany: boolean;
    companySubscription:string;
}
export class DefaultLayout extends React.Component<DefaultLayoutProps, IDefaultLayoutState> {

    private idleTimer: any;
    private sessionRenew: any;
    private _param: any;
    private autoSaveOrganizer: any;
    private autoSaveCustomQuestions: any;
    private isPendoInjected: boolean;
    constructor(props: DefaultLayoutProps, states: any) {
        super(props, states);
        this.idleTimer = null;
        this.isPendoInjected = false;
        this.state = {
            showTimeout: false,
            organizerData: SignerDocumentModel.createNullObject(),
            customQuestionsData: intialFormData,
            encodedClientId: "",
            uteSettings: { uteEnabled: false, uteModified: "" }
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: DefaultLayoutProps) {
        if (nextProps.organizerDocument?.data?.formGroup?.forms?.length > 0) {
            this.setState({
                organizerData: cloneDeep(nextProps.organizerDocument)
            });
        }
        if (nextProps.intialCustomQuestionsData.sections.length > 0) {
            this.setState({
                customQuestionsData: cloneDeep(nextProps.intialCustomQuestionsData)
            });
        }
        if (nextProps.clientData && nextProps.profileData) {
            const payload = {
                name: nextProps.clientData.name,
                email: nextProps.clientData.email,
                companyId: nextProps.profileData.companyId,
                companyName: nextProps.profileData.companyName,
                userId: nextProps.profileData.userId,
                isTestCompany: nextProps.profileData.isTestCompany,
                companySubscription:nextProps.profileData.companySubscription
            }
            this.injectPendoScript(payload)
        }
    }

    componentDidMount() {
        const clientId = sessionStorage.getItem('clientId');
        this._param = this.props.match.params;
        if (clientId) {
            if (!(/[,\-]/.test(clientId))) {
                this.setState({
                    encodedClientId: clientId
                });
            }
            this.props.requestAllTaxingAuthorities(clientId);
            this.props.requestHeaderInfo(clientId, false);
            this.props.getHeaderData(this._param.clientId);
            this.props.getClientInfo(this._param.clientId);
            this.props.getUTESettings(this._param.clientId, (data: HelperStore.IUteSettings) => {
                this.setState({
                    uteSettings: data
                });
            });

            if (!(window as any)._walkmeConfig?.smartLoad) {
                this.props.getWalkMeScript(clientId, (script: string) => {
                    if (script && script.length > 0) {
                        let scriptElement = document.createElement("script");
                        scriptElement.type = "text/javascript";
                        scriptElement.async = true;
                        scriptElement.text = script;
                        document.head.appendChild(scriptElement);
                        (window as any).loadWalkMe && (window as any).loadWalkMe();
                    }
                });
            }
        }
        this.sessionRenew = setInterval(() => {
            this.renewSession();
        }, RefreshTokenInterval); //Renew session after every 15 mins

        if (!(IsPreviewModeFromCache() || IsClientViewFromCache())) {

            this.autoSaveOrganizer = setInterval(() => {
                if (this.props.match.path == Common.Routes.Organizer)
                    this.autoSaveOrganizerData();
            }, AutoSaveInterval)

            this.autoSaveCustomQuestions = setInterval(() => {
                if (this.props.match.path == Common.Routes.CustomQuestions)
                    this.autoSaveCustomQuestionsData();
            }, AutoSaveInterval)
        }
    }

    componentWillUnmount() {
        clearInterval(this.sessionRenew);
        clearInterval(this.autoSaveOrganizer);
        clearInterval(this.autoSaveCustomQuestions);
    }

    private autoSaveOrganizerData = () => {
        const { organizerDocument } = this.props;
        const { organizerData } = this.state;
        if (!this.state.showTimeout && !isEqual(organizerData, organizerDocument)) {
            const clonedDocument = cloneDeep(organizerDocument);
            const organizerForms: OrganizerFormDataViewModel = OrganizerFormDataViewModel.create((clonedDocument as any).data.formGroup[0], "");
            let param: any = this.props.match.params;
            this.props.autoSaveOrganizer(param.clientId, organizerForms, organizerDocument, () => this.onAutoSaveSuccess(organizerDocument));
            this.autosaveSignProcessInfo();
        }
        else {
            logger.trackTrace("No data to save");
        }
    }
    onAutoSaveSuccess = (organizerForms: SignerDocumentModel) => {
        this.setState({
            organizerData: cloneDeep(organizerForms)
        });
        logger.trackTrace("Organizer autosaved");
        MarsNotifier.Success(AutoSave.Success, null);
    }
    private autoSaveCustomQuestionsData = () => {
        const { currentCustomQuestionsData, autoSaveCustomQuestions, match } = this.props;
        const { customQuestionsData, showTimeout } = this.state;

        if (!showTimeout && !isEqual(customQuestionsData, currentCustomQuestionsData)) {

            let param: any = match.params;
            autoSaveCustomQuestions(param.clientId, currentCustomQuestionsData, () => this.onAutoSaveCustomQuestionsSuccess(currentCustomQuestionsData));
        }
        else {
            logger.trackTrace("No custom question data to save");
        }
    }
    onAutoSaveCustomQuestionsSuccess = (formData: IFormData) => {

        this.setState({
            customQuestionsData: cloneDeep(formData)
        });

        logger.trackTrace("Custom Questions Autosaved");

        MarsNotifier.Success(AutoSave.Success, null);
    }
    private autosaveSignProcessInfo = () => {
        const LastVisitedPage = GetDataFromStorage(SignProcessInfoConstants.LastVisitedPage);
        const cachedSignProcessInfo = GetDataFromStorage(SignProcessInfoConstants.SignProcessInfo);
        if (LastVisitedPage && cachedSignProcessInfo) {
            let signingprocessInfo: SignProcessInfo = JSON.parse(cachedSignProcessInfo);
            signingprocessInfo.lastVisitedPageNo = LastVisitedPage;
            organizerService.updateSignProcessinfo(signingprocessInfo);
        }
    }
    private injectPendoScript = (data: IPendoPayload) => {
        const {
            companyId,
            companyName,
            userId,
            name,
            email,
            isTestCompany,
            companySubscription
        } = data;
        if (this.isPendoInjected) {
            return;
        }
        if (!companyId || !companyName || !userId || !name || !email) {
            console.log("User profile not available yet");
            return;
        }
        this.isPendoInjected = true;
        //TODO:Move this API key to env file
        const apiKey = process.env.REACT_APP_PENDO_API_KEY;

        (function (p: any, e: any, n: any, d: any, o: any) {
            var v: any, w: any, x: any, y: any, z: any;
            o = p[d] = p[d] || {};
            o._q = o._q || [];
            v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
            for (w = 0, x = v.length; w < x; ++w)
                (function (m) {
                    o[m] =
                        o[m] ||
                        function () {
                            o._q[m === v[0] ? "unshift" : "push"]([m].concat([].slice.call(arguments, 0)));
                        };
                })(v[w]);
            y = e.createElement(n);
            y.async = !0;
            y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
            z = e.getElementsByTagName(n)[0];
            z.parentNode.insertBefore(y, z);
        })(window, document, "script", "pendo", "");

        window.pendo?.initialize({
            visitor: {
                id: `${companyId}-${userId}-${email}`,
                email: email,
                name: name
            },
            account: {
                accountid: companyId,
                accountname: companyName,
                firmtype: isTestCompany ? 'Internal' : 'Live',
                subscription: companySubscription,
            }
        });
    };

    public render() {
        return (<div className="app-container">
            <IdleTimer
                ref={ref => { this.idleTimer = ref }}
                element={document}
                onIdle={this.onIdle}
                debounce={250}
                timeout={1000 * 60 * (SessionIdleMinutes - WarningVisibleMinutes)} />
            <Header
                headerInfo={this.props.headerInfo}
                isUserLoggedIn={true}
                organizerMetaData={this.props.organizerMetaData?.organizerMetadata}
                match={this.props.match}
                history={this.props.history}
                logout={this.props.logout}
                checkIfUserExistsInOneHub={this.props.checkIfUserExistsInOneHub}
                getOneHubRedirectUrl={this.props.getOneHubRedirectUrl}
                getOneHubHomeRedirectUrl={this.props.getOneHubHomeRedirectUrl}
                getOnehubRedirectURLForClientView={this.props.getOnehubRedirectURLForClientView}
                isUteEnabled={this.state.uteSettings.uteEnabled}
            />
            <div id="content-wrapper" className="content-wrapper">
                <Notification />
                {this.props.children}
            </div>
            <OverlayLoader />
            <Toaster />
            <SignalRWebSocket client_id={this.getClientId()}></SignalRWebSocket>
            <SessionTimeout
                match={this.props.match}
                showModal={this.state.showTimeout}
                onStayAlive={this.onStayAlive}
                countDownMinutes={WarningVisibleMinutes}
                loggedInUserInfo={this.props.headerInfo.loggedInUserInfo}
                logout={this.props.logout}
            />
        </div>
        );
    }

    private onIdle = (e: any) => {
        logger.trackTrace("Session expiry modal shown");
        logger.trackTrace(`Last active at : ${new Date(this.idleTimer.getLastActiveTime())}`);
        logger.trackTrace(`Total Idle time in ms: ${this.idleTimer.getTotalIdleTime()}`);
        this.setState({
            showTimeout: true
        });
    }

    private getClientId = (): string => {
        let param: any = this.props.match.params;
        return param?.clientId;
    }

    private onStayAlive = (e: React.SyntheticEvent<EventTarget>) => {
        e.preventDefault();
        let param: any = this.props.match.params;
        this.props.refreshToken(param.clientId, this.onCountDownComplete);
        this.setState({ showTimeout: false });
    }

    private renewSession = () => {
        let param: any = this.props.match.params;
        this.props.refreshToken(param.clientId, this.onCountDownComplete);
        logger.trackTrace("Session renewed");
    }
    private onCountDownComplete = () => {
        const { encodedClientId } = this.state;
        window.location.href = UrlConstants.EmailOTPPage + encodedClientId;
    }
}
