import { KBATransactionResultType } from "../../common/Enums";


export interface IKBATransactionResponse extends IBaseServiceResponse {
    questions: IKBAQuestionset;
    transactionStatus: KBATransactionResultType;
}

export interface IKBAQuestionset {
    title: string;
    questionSetId: number;
    question: IKBAQuestion[];
}

export interface IKBAQuestion {
    questionId: number;
    question: string;
    choices: IKBAAnswerChoice[];
}

export interface IKBAAnswers {
    transactionId: number;
    tryCount: number;
    questionSetId: number;
    answers: IKBAAnswer[];
}

export interface IKBAAnswer {
    questionId: number;
    choice: IKBAAnswerChoice;
}

export interface IKBAAnswerChoice {
    choiceId: number;
    choiceText: string;
}

export const initialKBAResponse: IKBATransactionResponse = {
    transactionId: 0,
    questions:
    {
        question: [],
        questionSetId: 0,
        title: ""
    },
    transactionStatus: KBATransactionResultType.questions,
    transactionDetails: ""
}

export interface IBaseServiceResponse {
    transactionId: number;
    transactionDetails: string;
}